import { createReducer, on } from "@ngrx/store";
import { PracticeApplicationFeatures } from "../state/practice-application-feature.state";
import { PracticeFeatureConfigSuccess, clearPracticeApplicationFeatureState } from "../action/practice-application-feature.action";

export const initialState: PracticeApplicationFeatures = {
    practiceApplicationFeaturesInformation: {
        practiceId: 0,
        isMinimizedCallSettingEnabled: false,
        isPatientPaymentEnabled: false,
        isAutoLateFeeEnabled: false,
        isTimeClockEnabled: false,
        isMediaLibraryEnabled: false,
        isFollowupTemplateEnabled: false,
        isAppointmentChairAlertEnabled: false,
        isNotificationEnabled: false,
        isRewardsEnabled: false,
        isScheduleTimeIndicatorEnabled: false,
        isSendEmailToLimitedPatientsEnabled: false,
        isModernTxCardEnabled: false,
        isAutoFollowupEnabled: false,
        isMmsenabled: false,
        isLetterQueueEnabled: false,
        isSendAppointmentConfirmationTextEnabled: false,
        isCallPopEnabled: false,
        autoLateFeeAmount: 0,
        autoLateFeeGracePeriod: 0,
        isSignAtHomeEnabled: false,
        signAtHomePrimaryEmail: "",
        signAtHomeSecondaryEmail: "",
        signAtHomeTertiaryEmail: "",
        createAtreatmentPlanWithoutAnExam: false,
        isVoIpenabled: false,
        isChatEnabled: false,
        isToothChartEnabled: false,
        isBulkEmailEnabled: false,
        isTaskEnabled: false,
        isSyncfusionEditorEnabled: false,
        isPatientLiveStatusCheckoutEnabled: false,
        isToothChartMinimized: false
    },
    practiceApplicationFeaturesEvents: {
        errorMessage: "",
        showErrorAlert: false,
        loading: false,
    },
};

export const practiceApplicationFeaturesReducer = createReducer(
    initialState,

    on(PracticeFeatureConfigSuccess, (state : PracticeApplicationFeatures, { practiceFeatures }) : PracticeApplicationFeatures => ({
        ...state,
        practiceApplicationFeaturesInformation: practiceFeatures
    })),

    on(clearPracticeApplicationFeatureState, () => initialState),

);