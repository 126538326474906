import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, forwardRef, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl, ControlValueAccessor, NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors, Validator
} from '@angular/forms';
import { CalendarType, DateInputFillMode, DateInputRounded, DateInputSize, PopupSettings } from '@progress/kendo-angular-dateinputs';
import { MASK_PATTERN } from '../../../constant/shared-pattern-regex-asset';
import moment from 'moment'
import { DatePipe } from '@angular/common';
import { ScreenSizeService } from './../../../services/screen-size.service';
import { deviceWidth } from '../../../constant/shared-constant';
import { DateUtilService } from '../../../services/date-util.service';

@Component({
  selector: 'app-form-control-datepicker',
  template: `
          <div class="form-group" [class.has-error]="hasError()">

            <label  *ngIf="labelName">{{ labelName }}
              <span *ngIf="required" class="required_field ms-1">*</span>
            </label>

            <kendo-datepicker   #datePickerElement [popupSettings]="popupSettings"
              [navigation]="false"
              [incompleteDateValidation]="true"
              formatPlaceholder="wide"
              [placeholder]="placeholder"
              format={{dateFormat}}
              [size]="size"
              [rounded]="rounded"
              [fillMode]="fillMode"
              [calendarType]="calendarType"
              [disabledDates]="disabledDates"
              [max]="maxDate"
              (valueChange)="datePickerChangeEvent($event);"
              [ngModel]="selectedDate"
              (blur)="onTouched()"
              [readOnlyInput]="readOnlyValue" 
              (close)="datePickerCloseEvent()"
            >
            </kendo-datepicker>
            
            <ng-container *ngIf="hasError()">
              <app-validation-error [errors]="control?.errors" [fieldName]="errorMessage ? errorMessage : (labelName??fieldName)">
              </app-validation-error> 
            </ng-container>
          </div>
  `,
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
  .datepicker-custom-change{
    .k-button-flat-primary{color:#4099ff!important;}
    .k-calendar {
      .k-calendar-td {
        &.k-focus{
          .k-link{
          box-shadow:none!important;}
        }
        &:hover{.k-link{
          background:transparent!important;
        }}
        padding:2px!important;
        &.k-selected{
          .k-link{
            &:hover{
              color: #fff !important;font-weight:bold;border-color:transparent!important;font-weight: normal!important;
            }
            background:#4099ff!important;border-color:transparent!important;
          }
        }
      }
    .k-calendar-view .k-today{color:#4099ff!important;font-weight:bold;}
    }
    .k-link{
      &:hover{
        background: transparent!important;border:1px solid #4099ff!important;
        color: #4099ff !important;font-weight:bold;padding:2px!important;
      }
    }
    .k-calendar-table{
      .k-calendar-thead,.k-calendar-tbody{
        .k-calendar-tr{background:#fff !important;
          th,td{background:#fff !important;font-size:12px !important;border:0 !important;padding:2px !important;width:32px !important;height:32px !important;}
          td{font-size:14px !important;}
          }
      }
    }
  }
  `
  ],
  // (valueChange)="datePickerChangeEvent($event);"
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlDatepickerComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormControlDatepickerComponent),
      multi: true
    }
  ],
})
export class FormControlDatepickerComponent implements ControlValueAccessor, Validator {

  @Input() labelName?: string;
  @Input() required?: boolean;
  @Input() FastNavigation?: boolean;
  @Input() size: DateInputSize;
  @Input() rounded: DateInputRounded;
  @Input() fillMode: DateInputFillMode;
  @Input() calendarType: CalendarType;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() disableFutureDates!: boolean;
  @Input() disablePastDates!: boolean;
  @Input() placeholder!: string;
  @Output() dateChangeEvent = new EventEmitter<any>();
  @Input() errorMessage!: string;
  @Input() fieldName?: string;

  @ViewChild('datePickerElement', { read: ElementRef }) datePickerElement!: ElementRef;


  disabledDates: (date: Date) => boolean;
  dateFormat: string;
  control: AbstractControl | undefined;
  selectedDate!: Date | null;
  onChange: any = () => { };
  onTouched: any = () => { };
  disabled: boolean;
  selectedDateString!: string;
  readOnlyValue = false

  constructor(private datePipe: DatePipe, private screenSizeService: ScreenSizeService, private dateUtilService: DateUtilService) {
    this.disableFutureDates = true;
    this.disablePastDates = false;
    this.FastNavigation = true;  //enable fast navigation
    this.size = 'large';  //small medium(Default) large none
    this.rounded = 'medium';  //small medium(Default) large full none
    this.fillMode = 'solid';  //solid(Default) flat outline none
    this.calendarType = 'infinite'  // infinite(default) classic
    this.dateFormat = MASK_PATTERN.date;
    this.minDate = new Date(1900, 0, 1);
    const today = new Date();
    this.maxDate = new Date(today.getFullYear() + 100, today.getMonth(), today.getDate());
    this.disabledDates = (date: Date): boolean => {
      return date > today;
    };
    this.placeholder = ''
    this.disabled = false;
  }

  public popupSettings: PopupSettings = {
    appendTo: "component",
    animate: false,
    popupClass: "datepicker-custom-change",
  }

  ngOnInit(): void {
    if (!this.disableFutureDates) {
      this.disabledDates = (): boolean => {
        return false;
      };
    }

    if (this.disablePastDates) {
      this.disabledDates = (date: Date): boolean => {
        const currentDate = new Date(this.dateUtilService.GetApplicationCurrentDateTime().setHours(0, 0, 0, 0));
        const disableDate = currentDate > this.minDate ? currentDate : this.minDate;
        return date < disableDate;
      };
    }

    this.screenSizeService.screenWidth$.subscribe((screenInfo) => {
      if (screenInfo[0] <= deviceWidth.desktop) {
        this.readOnlyValue = true
      } else {
        this.readOnlyValue = false
      }
    });
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    this.control = control;
    return null;
  }
  writeValue(value: any): void {
    if (value)
      this.selectedDate = moment(value).toDate();
    else
      this.selectedDate = null;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  hasError(): boolean | undefined {
    return this.control?.invalid && (this.control?.touched || this.control?.dirty);
  }

  datePickerChangeEvent(event: Date) {
    this.selectedDateString = this.datePipe.transform(event, 'yyyy-MM-dd') || '';
    this.onChange(this.selectedDateString);
    this.dateChangeEvent.emit(event);
  }

  datePickerCloseEvent() {
    if (this.selectedDate) {
      if (this.datePickerElement && this.datePickerElement.nativeElement) {
        this.datePickerElement.nativeElement.querySelector('input').blur();
      }
    }
  }
}