import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType, concatLatestFrom } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, exhaustMap, filter, map, mergeMap, of, switchMap, take } from "rxjs";
import { PracticeInfoAPIService } from "../../api/practice-info-api.service";
import {
    GetPracticeCoreInfoFailure,
    getAppointmentTypesFailure,
    getAppointmentTypesSuccess,
    getLetterTemplatesFailure,
    getLetterTemplatesSuccess,
    getLocationsFailure, getLocationsSuccess, getPracticeCoreInfoSuccess, getPracticeCurrentDateTimeFailure, getPracticeCurrentDateTimeSuccess, getProvidersFailure, getProvidersSuccess,
    invokeGetAppointmentTypes,
    invokeGetLetterTemplates,
    invokeGetLocationByEmail, invokeGetPracticeCoreInfo, invokeGetPracticeCurrentDateTime, invokeGetProviders, invokePracticeUserLocationByPracticeId, 
    invokePracticeUserLocationByPracticeIdFailure, invokePracticeUserLocationByPracticeIdSuccess, stopPracticeLocationLoading
} from "../reducer/practice-core.reducer";
import { selectLocations, selectPracticeAppointmentTypes, selectPracticeLetterTemplates, selectPracticePersonalCoreInfo } from "../selector/practice-core-selector";
import { PracticeLocation } from "../state/practice-core";

@Injectable()
export class PracticeCoreEffect {
    constructor(
        private actions$: Actions,
        private practiceInfoAPIService: PracticeInfoAPIService,
        private store: Store,
    ) { }

    loadProviderList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invokeGetProviders),
            exhaustMap(() => {
                return this.practiceInfoAPIService.getPracticeProviders().pipe(
                    take(1),
                    map((data) => getProvidersSuccess({ practiceProviders: data })),
                    catchError(errorMessage => of(getProvidersFailure({ errorMessage: errorMessage })))
                );
            })
        )
    }
    );

    loadLocationList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invokeGetLocationByEmail),
            exhaustMap((action) => {
                return this.practiceInfoAPIService.getPracticeLocationsByEmail(action.email).pipe(
                    take(1),
                    map((data: PracticeLocation[]) => getLocationsSuccess({ practiceLocations: data })),
                    catchError(errorMessage => of(getLocationsFailure({ errorMessage: errorMessage })))
                );
            })
        );
    });
   
    getPracticeCoreInfo$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invokeGetPracticeCoreInfo),
            concatLatestFrom(() => this.store.select(selectPracticePersonalCoreInfo)),
            filter(([, practiceInfo]) => !practiceInfo),
            mergeMap(([action]) => {
                return this.practiceInfoAPIService.getPracticeInfo(action.practiceId).pipe(
                    map(practicePersonalInfo => getPracticeCoreInfoSuccess({ practicePersonalInfo })),
                    catchError(errorMessage => of(GetPracticeCoreInfoFailure({ errorMessage: errorMessage })))
                );
            })
        )
    }
    )

    loadAppointmentTypeList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invokeGetAppointmentTypes),
            concatLatestFrom(() => this.store.select(selectPracticeAppointmentTypes)),
            filter(([, appointmentTypelist]) => !appointmentTypelist || appointmentTypelist.length === 0),
            mergeMap(([action, _]) => {
                return this.practiceInfoAPIService.getPracticeAppointmentTypes(action.practiceId).pipe(
                    map((data) => getAppointmentTypesSuccess({ appointmentTypes: data })),
                    catchError(errorMessage => of(getAppointmentTypesFailure({ errorMessage: errorMessage })))
                );
            })
        )
    }
    );

    loadLetterTemplateList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invokeGetLetterTemplates),
            concatLatestFrom(() => this.store.select(selectPracticeLetterTemplates)),
            filter(([, letterTemplatelist]) => !letterTemplatelist || letterTemplatelist.length === 0),
            mergeMap(([action, _]) => {
                return this.practiceInfoAPIService.getPracticeLetterTemplateNames(action.practiceId).pipe(
                    map((data) => getLetterTemplatesSuccess({ letterTemplates : data })),
                    catchError(errorMessage => of(getLetterTemplatesFailure({ errorMessage: errorMessage })))
                );
            })
        )
    }
    );
    
    loadLocationsByPracticeId$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invokePracticeUserLocationByPracticeId),
            concatLatestFrom(() => this.store.select(selectLocations)),
            mergeMap(([action, locations]) => {
                if (locations.length > 0) {
                    return of(stopPracticeLocationLoading());
                }
                return this.practiceInfoAPIService.getPracticeLocations(action.practiceId).pipe(
                    map((data: PracticeLocation[]) => invokePracticeUserLocationByPracticeIdSuccess({ practiceLocations: data })),
                    catchError(errorMessage => of(invokePracticeUserLocationByPracticeIdFailure({ errorMessage: errorMessage })))
                );
            })
        );
    });

    loadPracticeCurrentTimeByPracticeId$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invokeGetPracticeCurrentDateTime),
            switchMap((action) => {
                return this.practiceInfoAPIService.getPracticeCurrentDateTime(action.practiceId).pipe(
                    map((practiceCurrentDateTime) => getPracticeCurrentDateTimeSuccess({ practiceCurrentDateTime})),
                    catchError(errorMessage => of(getPracticeCurrentDateTimeFailure({ errorMessage: errorMessage })))
                );
            })

        );
    });
}
