<div>
    <div class="remove-file-icon img-radius">
        <div class="wid-150 hei-150 d-flex flex-column justify-content-center align-items-center">
            <h5 class="text-center text-white font-size-auto" *ngIf="imageName">{{imageName}}</h5>
            <div class="d-flex flex-wrap justify-content-between gap-2 mx-1">
                <div *ngIf="showUpload" (click)="showImageUploadfunc(false,false)"
                    class="d-flex bg-white shadow wid-30 hei-30 rounded justify-content-center align-items-center"
                    title="Upload">
                    <img src="assets/images/svg/upload.svg" class="wid-20">
                </div>
                <div *ngIf="imageSrc && showDownload" (click)="browserDownloadImage()"
                    class="d-flex bg-white shadow wid-30 hei-30 rounded justify-content-center align-items-center"
                    title="Download">
                    <ng-container *ngIf="!isDownloading else spinner">
                        <img src="assets/images/svg/download.svg" class="wid-20">
                    </ng-container>
                    <ng-template #spinner>
                        <span class="d-flex justify-content-center align-items-center w-100 rounded-3">
                            <img src="assets/images/svg/spinner-loader.svg" width="20" style="filter: invert(52%) sepia(13%) saturate(4395%) hue-rotate(187deg) brightness(102%) contrast(101%);">
                          </span>
                    </ng-template>
                </div>
                <div *ngIf="imageSrc && showEdit" (click)="edit()"
                    class="d-flex bg-white shadow wid-30 hei-30 rounded justify-content-center align-items-center"
                    title="Edit">
                    <img src="assets/images/svg/edit.svg" class="wid-20">
                </div>
                <div *ngIf="showWebcam" (click)="showImageUploadfunc(true,false)"
                    class="d-flex bg-white shadow wid-30 hei-30 rounded justify-content-center align-items-center"
                    title="Webcam">
                    <img src="assets/images/svg/webcam.svg" class="wid-20">
                </div>
                <div *ngIf="imageSrc && showViewer"  (click)="viewImage.emit()"
                    class="d-flex bg-white shadow wid-30 hei-30 rounded justify-content-center align-items-center"
                    title="Viewer">
                    <img src="assets/images/svg/viewer.svg" class="wid-20">
                </div>
                <div *ngIf="imageSrc && showReset" (click)="resetImage.emit()"
                    class="d-flex bg-white shadow wid-30 hei-30 rounded justify-content-center align-items-center"
                    title="Reset">
                    <img src="assets/images/svg/recycle.svg" class="wid-20">
                </div>
                <div *ngIf="showAcquireImage" (click)="showImageUploadfunc(false,true)"
                    class="d-flex bg-white shadow wid-30 hei-30 rounded justify-content-center align-items-center"
                    title="Acquire">
                    <img src="assets/images/svg/acquire.svg" class="wid-20">
                </div>
                <div *ngIf="imageSrc && showRemove" (click)="removeImage.emit()"
                    class="d-flex btn-light-danger shadow wid-30 hei-30 rounded justify-content-center align-items-center delete-curve"
                    title="Delete">
                    <i class="feather icon-trash-2 cursor-pointer"></i>
                </div>
            </div>
        </div>
    </div>
     
    <ng-container *ngIf="isLoadingImage">
        <div class="border d-flex align-items-center justify-content-center dashboard-summary-border w-100">
            <div class="skeleton m-0 p-0">
                <div class="line" style="height:193px;width:100%;border-radius:20px">
                    <h1 class="invisible text-break">Skeleton</h1>
                </div>
            </div>
        </div>

        <div class="d-flex flex-wrap imaging-loader d-none">
            <div class="row-f">
                <div class="column-f">
                    <div class="skeleton m-0 p-0">
                        <div class="line" style="height:193px;width:100%;border-radius:20px">
                            <h1 class="invisible text-break">SkeletonSkeleton</h1>
                        </div>
                    </div>
                </div>
                <div class="column-f">
                    <div class="skeleton m-0 p-0">
                        <div class="line" style="height:193px;width:100%;border-radius:20px">
                            <h1 class="invisible text-break">SkeletonSkeleton</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row-f">
                <div class="column-f">
                    <div class="skeleton m-0 p-0">
                        <div class="line" style="height:193px;width:100%;border-radius:20px">
                            <h1 class="invisible text-break">SkeletonSkeleton</h1>
                        </div>
                    </div>
                </div>
                <div class="column-f">
                </div><div class="skeleton m-0 p-0">
                    <div class="line" style="height:193px;width:100%;border-radius:20px">
                        <h1 class="invisible text-break">SkeletonSkeleton</h1>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="image-width" *ngIf="imageSrc && !isLoadingImage && uploadFileType != 'application/pdf'">
        <ng-container>
            <img [src]="imageSrc" style="width: 100%;" height="100" class="upload-practice" *ngIf="convertToBase64src">
            <img class="img-radius img-fluid" [src]="imageSrc"  *ngIf="!convertToBase64src"/>
        </ng-container>
    </div>
    <div class="image-width" *ngIf="imageSrc && !isLoadingImage && uploadFileType == 'application/pdf'">
        <ng-container>
            <img src="assets/images/pdf-uploaded-file.png" style="width: 100%;" height="100" class="upload-practice">  
        </ng-container>
    </div>
    <div class="image-width" *ngIf="!imageSrc && !isLoadingImage">
        <img class="img-radius img-fluid" [src]="defaultImagePath" />
    </div>
</div>

<ng-container *ngIf="showImageUpload">
    <app-popup-modal #imageUploadModal title="Upload Image" size="large" 
    (onClose)="showImageUpload = !showImageUpload">
        <app-document-upload (submitClick)="updateimageUpload($event);showImageUpload=false"
            [allowedFileTypes]="['image/jpeg', 'image/png', 'image/bmp', 'image/webp']" [patientImage]="patientImage"
            [uploadDocumentType]="uploadDocumentType" submitButtonName="Save Image" [isWebcamUpload]="isWebcamUpload" 
            [isAcquireImageUpload]="isAcquireImageUpload">
        </app-document-upload>
    </app-popup-modal>
</ng-container>