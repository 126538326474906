import { NAME_VALUE_NUM_TYPE, NAME_VALUE_TYPE } from "../../shared/types/shared-types";

export const CATEGORY_TEYPES: NAME_VALUE_NUM_TYPE[] = [
    { name: 'Date', value: 1 },
    { name: 'Month', value: 2 },
    { name: 'Quarter', value: 3 },
    { name: 'Year', value: 4 },
    { name: 'Custom', value: 5 },
].sort((a, b) => a.name > b.name ? 1 : -1)

export const SERVICECONTRACT_CATEGORY_TYPES = [
    { name: 'All', value: null },
    { name: 'Treatment', value: "Treatment" },
    { name: 'Miscellaneous', value: "Misc" },
].sort((a, b) => a.name > b.name ? 1 : -1)

export const SERVICECONTRACT_CATEGORY = {
    Treatment: 'Treatment',
    Miscellaneous: 'Miscellaneous',
}

export const CATEGORY = {
    Date: 'Date',
    Month: 'Month',
    Quarter: 'Quarter',
    Year: 'Year',
    Custom: 'Custom'
}

export const QUARTER_TYPES: NAME_VALUE_NUM_TYPE[] = [
    { name: 'Q1', value: 1 },
    { name: 'Q2', value: 2 },
    { name: 'Q3', value: 3 },
    { name: 'Q4', value: 4 },
].sort((a, b) => a.name > b.name ? 1 : -1)

export const MONTHS: NAME_VALUE_NUM_TYPE[] = [
    { name: 'January', value: 1, shortName: "Jan" },
    { name: 'February', value: 2, shortName: "Feb" },
    { name: 'March', value: 3, shortName: "Mar" },
    { name: 'April', value: 4, shortName: "Apr" },
    { name: 'May', value: 5, shortName: "May" },
    { name: 'June', value: 6, shortName: "Jun" },
    { name: 'July', value: 7, shortName: "Jul" },
    { name: 'August', value: 8, shortName: "Aug" },
    { name: 'September', value: 9, shortName: "Sep" },
    { name: 'October', value: 10, shortName: "Oct" },
    { name: 'November', value: 11, shortName: "Nov" },
    { name: 'December', value: 12, shortName: "Dec" },
];
export const AGEGROUP = [
    { name: 'All', value: null },
    { name: 'Adult', value: 'Adult' },
    { name: 'Child', value: 'Child' }
]

export const APPOINTMENTCONFIRM: NAME_VALUE_TYPE[] = [
    { name: 'Yes', value: 'Yes' },
    { name: 'No', value: 'No' }
]

export const paymentMethods = [
    { name: "All", value: null },
    { name: "Cash", value: "Cash" },
    { name: "Paper Check", value: "PaperCheck" },
    { name: "Cashier Check", value: "CashierCheck" },
    { name: "Desktop Transaction", value: "DesktopTransaction" },
    { name: "Other Collection", value: "OtherCollection" },
    { name: "Credit or Debit Card", value: "CreditOrDebitCard" },
    { name: "ACH", value: "ACH" },
    { name: "Wallet", value: "Wallet" },
    { name: "Void", value: "Void" }
]

export const paidBy = [
    { name: "All", value: null },
    { name: "Patient", value: "Patient" },
    { name: "Insurance", value: "Insurance" }
]

export const transactionStatus = [
    { name: "All", value: null },
    { name: "Yet to attempt", value: "Yet to attempt" },
    { name: "Pending ", value: "Pending" },
    { name: "Completed  ", value: "Completed" }
]

export const dueType = [
    { name: "All", value: null },
    { name: "Down Payment", value: "Down Payment" },
    { name: "Due Payment", value: "Due Payment" }
]

export const reportName = {
    dailyInvoice: "dailyinvoice",
    appointmentDetail: "Appointment_Detail_Report",
    appointmentDetailWithPayment: "Appointment_Detail_Report_With_Payment",
    appointmentSummary: "Appointment_Summary_Report",
    examResult: "Exam_Result_Report",
    conversionDeail: "Conversion_Detail_Report",
    conversionSummary: "Conversion_Summary_Report",
    delinquentResult: "Delinquent_Result_Report",
    daySheet: "Day_Sheet_Report",
    receivable: "receivable",
    insuranceAR: "Insurance_Receivable_Report",
    patientAR: "Patient_Receivable_Report",
    monthlyBalanceSheet: "monthlyBalanceSheet",
    doNotPuruseReport: "Do_Not_Puruse_Report",
    referralReport: "Referral_Report"
}

export const reportMessage = {
    dailyInvoiceDateWarningMessage: "The interval between the 'start' and 'end' dates exceeds 31 days. Please select a different date range which is less than 31 days.",
    exportWarningMessage: "Export is allowed only within 365 days. The date difference between the 'from' and 'to' dates exceeds 365 days. Please choose different dates.",
    startDateEndDateWarningMessage: "Start date must be earlier than the end date."
}

export const abbreviatedMonthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

export const browserNames = {
    ipadSafari: "ipadSafari"
}

export const sortingValues = {
    desc: 'desc' as const,
    asc: 'asc' as const,
}

export const formatPattern = {
    dateTime: 'MM/dd/yyyy hh:mm a',
    date: 'MM/dd/yyyy',
    time: 'hh:mm a'
}

export const defaultSortColumn = {
    appointmentDetail: "appointmentDateTime",
    appointmentSummary: "practiceAppointmentName",
    examResult: "patientFirstName",
    conversionDetail: "patientFirstName",
    conversionSummary: "practiceAppointmentGroupName",
    treatmentContractDateOfContractSigned:"treatmentContractDateOfContractSigned",
    transactionDate:"transactionDate",
    contractId:"patienttreatmentcontractid"
}

export const reportConst = {
    examResultTxRecommended: "Tx Recommended"
}

export const officeCollectedPaymentMethods = ["Cash", "PaperCheck", "CashierCheck", "DesktopTransaction", "OtherCollection", "Wallet"]

export const cardCollectedPaymentMethods = ["CreditOrDebitCard", "ACH", "Void"]

export const receivableType = {
    patientar: 'patient',
    insurancear: 'insurance'
}

export const followupStatusTypes = {
    donotpursue: 'Do not pursue',
    declineTreatment: 'Decline Treatment'
}

export const referralByConst = {
    clinicOrDoctor: "Clinic or Doctor",
    friendsOrFamily: "Friends or Family"
}

export const yesOrNoConstant = {
    yes: "Yes",
    no: "No"
}

export const daySummaryTooltip = {
    totalDeposit: '(Total Office Collection + Total Card Collection + Total Insurance)',
    totalDepositWithoutOffice: '(Total Card Collection + Total Insurance)',
    totalDepositWithoutCard: '(Total Office Collection + Total Insurance)'
}

export const TREATMENT_STATUS = [
    { name: 'All', value: null },
    { name: 'Active', value: 'Active' },
    { name: 'Dismissed', value: 'Dismissed' },
]

export const PATIENT_STATUS = [
    { name: 'All', value: null },
    { name: 'Active', value: 'Active' },
    { name: 'Inactive', value: 'Inactive' }
]
