import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import {
  AbstractControl, ControlValueAccessor,
  NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator
} from '@angular/forms';

@Component({
  selector: 'app-form-control-selectbox',
  template: `
        <div class="form-group" [class.has-error]="hasError()">
          <label *ngIf="label">{{label}}
            <span *ngIf="required" class="required_field ms-1">*</span>
          </label>
          <ng-select class="select-wrap-text ng-select-customize" 
            [appendTo]="appendTo"
            [items]="items" 
            [closeOnSelect]="true" 
            [searchable]="searchable"
            [bindLabel]="bindLabel" 
            [placeholder]="placeholder" 
            [bindValue]="bindValue"
            [clearable]="clearable"
            [multiple]="multiple"
            (clear)="clearEvent.emit()"
            [disabled]="disabledField || disabled"
            [id]="label"

            [ngModel]="updatedValue"
            (ngModelChange)="selectBoxChanged($event)"
            (blur)="onTouched()"
          >
          </ng-select>

          <ng-container *ngIf="hasError()">
            <app-validation-error [errors]="control?.errors" [errorMessage]= "errorMessage" [fieldName]="label">
            </app-validation-error>
          </ng-container>
          
        </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlSelectboxComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormControlSelectboxComponent),
      multi: true
    }
  ],
})
export class FormControlSelectboxComponent implements ControlValueAccessor, Validator {

  @Input() label!: string;
  @Input() required!: boolean;
  @Input() formControlName?: string;
  @Input() placeholder!: string;
  @Input() bindLabel!: string;
  @Input() bindValue!: string;
  @Input() items!: any;
  @Input() searchable!: boolean;
  @Input() clearable!: boolean;
  @Input() multiple!: boolean;
  @Input() errorMessage!: string;
  @Input() appendTo = "";
  @Input() disabledField: boolean = false;
  
  @Output() clearEvent = new EventEmitter<boolean>();
  @Output() changeEvent = new EventEmitter<any>();
  @Output() selectedEvent = new EventEmitter<any>();

  control: AbstractControl | undefined;
  updatedValue: any;
  onChange: any = () => { };
  onTouched: any = () => { };
  disabled!: boolean;
  selectedValues: any[] = [];

  constructor() {
    this.searchable = true;
    this.clearable = true;
    this.multiple = false;
    this.placeholder = 'Select an Option'
  }

  ngOnInit() {
    this.updatedValue = null;
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    this.control = control;
    return null;
  }

  writeValue(value: any): void {
    if (!value) {
      this.updatedValue = value;
    } else if (typeof value === 'object' && !Array.isArray(value)) {
      if (this.bindValue in value) {
        this.updatedValue = value[this.bindValue];
      } else {
        this.updatedValue = value;
      }
    } else if (Array.isArray(value)) {
      this.updatedValue = value
        .map(item => item ? item[this.bindValue] : undefined)
        .filter(val => val !== undefined);
    } else {
      this.updatedValue = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChangeEvent(event: any) {
    this.control?.markAsTouched();
    this.onChange(event)
  }

  selectedAlone(event: any) {
    if (!this.multiple) return;
    const previousValues = this.selectedValues;
    const currentValues: any[] = event;
    if (Array.isArray(currentValues)) {
      const addedValues = currentValues?.filter(value => !previousValues.includes(value));
      if (addedValues.length > 0)
        this.selectedEvent.emit(event);
      this.selectedValues = currentValues;
    }
  }

  selectBoxChanged(event: any) {
    this.control?.markAsTouched();
    this.onChange(event);
    this.changeEvent.emit(event);
    this.selectedAlone(event)
  }

  hasError(): boolean | undefined {
    return this.control?.invalid && (this.control?.touched || this.control?.dirty);
  }
}