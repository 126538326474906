import { Component } from '@angular/core';
import { AuthService } from 'src/app/features/user/services/authentication/auth.service';
import { UserRoleService } from 'src/app/features/user/services/user-role.service';

@Component({
    selector: 'app-page-not-found',
    template: `
          <div class="col-lg-12 text-center">
            <img width="50%" src="assets/images/maintenance/no-access.png">
            <div style="width:50%;margin:0px auto">
                <p class="f-20 m-0 f-w-400 mb-4"> Oops! It seems you don't have permission to access this page.</p> 
                <p>Please make sure you are logged in with the correct credentials or contact the administrator for assistance</p>
                <p class="f-20 m-0 f-w-400 mb-2">If you're not sure where to go, you can always navigate back to the homepage.</p>
                <button class="btn btn-light-primary m-t-40" (click)="backToHomeClick()">Back to home</button>
            </div>
        </div>
        `,
})
export class NoPermissionComponent {
    constructor(private authService: AuthService, private userRoleService: UserRoleService){
    }
  
    backToHomeClick(){
       const loggedInUserRole = this.authService.getUserRoleFromToken();
       this.userRoleService.loadLandingPageByUserRolePermission(loggedInUserRole);
    }
}