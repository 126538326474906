import { CanActivateFn, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from "./auth.service"
import { inject } from '@angular/core';

export const AuthGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const authService = inject(AuthService);
    const router = inject(Router);
    const token = authService.getToken();
    if (!token) {
        router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
    return true;
}
