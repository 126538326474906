<ng-container *ngIf="isLoading else contractplan">
    <app-form-skeleton [fields]="10"></app-form-skeleton>
    <app-form-skeleton [fields]="10"></app-form-skeleton>
</ng-container>
<ng-template #contractplan>

    <div class="card px-3 pt-4 pb-3 ledger_print" printSection>

        <div class="printOnly" *ngIf="!hideLedgerSummary">
            <div class="common_print_header my-2 ">
                <div>
                    <div><label class="f-w-900">Patient Name :</label><span
                            class="ms-2">{{patientSummary.patientName}}</span></div>
                </div>
                <div>
                    <div><label class="f-w-900">Practice Name :</label><span
                            class="ms-2">{{patientSummary.practiceName}}</span></div>
                </div>
                <div>
                    <div><label class="f-w-900">DOB :</label><span class="ms-2">{{patientSummary.patientFormattedDob}}</span>
                        <span>
                            <div>
                                <label class="f-w-900">Age :</label><span class="ms-2">{{patientSummary.patientAge}}</span>
                            </div>
                        </span>
                    </div>
                </div>
                <div>
                    <div><label class="f-w-900">Location Name :</label><span
                            class="ms-2">{{this.selectedPatientTreatmentInstallmentPlan.practiceLocationName}}</span></div>
                </div>
            </div>
            <h6 class="h6" style="text-transform:uppercase;">{{!isWalletLedger ? 'Ledger Details' : 'Wallet Ledger Details'}}
            </h6>
            <ng-container *ngIf="isWalletLedger">
                <ng-container *ngFor="let options of this.walletOptions; let i = index">
                    <ng-container *ngIf="this.selectdWalletOptions?.bindValue! == options.bindValue">
                        <h6  class="h6" >{{options.bindLabel}} - ${{options.walletAmount | number:'1.2-2' }}</h6>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>

        <ng-container *ngIf="!isWalletLedger">
            <div class="d-flex justify-content-between mb-2 align-items-center" printRemove>
                <h5 class="f-14 mb-0">Ledger</h5>
                <div (click)="isLedgerDetailsCollapsed = !isLedgerDetailsCollapsed" *ngIf="!hideLedgerSummary">
                    <button type="button" class="btn btn-light-primary patientsearchfilter">
                        <i
                            class="fa fa-regular {{isLedgerDetailsCollapsed ? 'fa-chevron-up' : 'fa-chevron-down' }} me-2"></i>
                        <span>{{isLedgerDetailsCollapsed ? 'Hide' : 'Show'}} Ledger Details </span>
                    </button>
                </div>
            </div>

            <div class="position-relative">
                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 ledger-details-card"
                        [ngClass]="isLedgerDetailsCollapsed ? 'd-block' : 'd-none'" *ngIf="!hideLedgerSummary">
                        <div class="card px-3 mb-0"
                            *ngIf="(this.selectedpatientLedgerSummary) && (this.selectedPatientTreatmentInstallmentPlan)">
                            <table class="table table-borderless cost-table mb-0">
                                <tr>
                                    <td>
                                        <p class="f-14 text-truncate" style="width: 150%;"
                                            *ngIf="(!this.selectedPatientTreatmentInstallmentPlan.practiceInsuranceCompanyId)">
                                            {{this.selectedPatientTreatmentInstallmentPlan.practiceTreatmentOptionName}}
                                        </p>
                                        <p class="f-14 text-truncate" style="width: 150%;"
                                            *ngIf="(this.selectedPatientTreatmentInstallmentPlan.practiceInsuranceCompanyId)">
                                            {{this.selectedPatientTreatmentInstallmentPlan.practiceInsuranceCompanyName}}
                                        </p>
                                    </td>
                                    <td>
                                        <p class="f-14 fw-bold text-right">
                                            ${{this.selectedpatientLedgerSummary.totalCharges | number:'1.2-2'}}</p>
                                    </td>
                                </tr>
                                <tr *ngIf="(!this.selectedPatientTreatmentInstallmentPlan.practiceInsuranceCompanyId)">
                                    <td>
                                        <p class="f-14">Discount</p>
                                    </td>
                                    <td>
                                        <p class="f-14 fw-bold text-right">
                                            (${{this.selectedpatientLedgerSummary.totalDiscount | number:'1.2-2'}})</p>
                                    </td>
                                </tr>
                                <tr *ngIf="(!this.selectedPatientTreatmentInstallmentPlan.practiceInsuranceCompanyId)">
                                    <td>
                                        <p class="f-14">Est.Insurance Coverage</p>
                                    </td>
                                    <td>
                                        <p class="f-14 fw-bold text-right">
                                            (${{this.selectedpatientLedgerSummary.totalInsuranceCoverage |
                                            number:'1.2-2'}})</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="f-14">Production</p>
                                    </td>
                                    <td>
                                        <p class="f-14 fw-bold text-right">
                                            ${{this.selectedpatientLedgerSummary.totalProduction | number:'1.2-2'}}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="f-14">+ Adjustment</p>
                                    </td>
                                    <td>
                                        <p class="f-14 fw-bold text-right">
                                            ${{this.selectedpatientLedgerSummary.totalDebitAdjustment | number:'1.2-2'}}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="f-14">- Adjustment</p>
                                    </td>
                                    <td>
                                        <p class="f-14 fw-bold text-right">
                                            (${{this.selectedpatientLedgerSummary.totalCreditAdjustment |
                                            number:'1.2-2'}})</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="f-14">Responsibility</p>
                                    </td>
                                    <td>
                                        <p class="f-14 fw-bold text-right">
                                            ${{this.selectedpatientLedgerSummary.resposibility | number:'1.2-2'}}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="f-14">Payment</p>
                                    </td>
                                    <td>
                                        <p class="f-14 fw-bold text-right">
                                            (${{this.selectedpatientLedgerSummary.totalPaymentCollected |
                                            number:'1.2-2'}})</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="f-14">Refund</p>
                                    </td>
                                    <td>
                                        <p class="f-14 fw-bold text-right">
                                            ${{this.selectedpatientLedgerSummary.totalRefund | number:'1.2-2'}}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="f-14">Balance</p>
                                    </td>
                                    <td>
                                        <p class="f-14 fw-bold text-right">
                                            ${{this.selectedpatientLedgerSummary.totalBalance | number:'1.2-2'}}</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="ledger-details-card ledger-details-top "
                        [ngClass]="isLedgerDetailsCollapsed ? 'col-xl-6 col-lg-6 col-md-6 col-sm-12 ' : 'col-xl-12 col-lg-12 col-sm-12 '">
                        <div class="card px-3 mb-0" *ngIf="!hideLedgerSummary && (this.selectedInstallmentPlanSummary)"
                            [ngClass]="isLedgerDetailsCollapsed ? 'd-block' : 'd-none'">
                            <table class="table table-borderless cost-table mb-0">
                                <tr>
                                    <td>
                                        <p class="f-14">Status</p>
                                    </td>
                                    <td>
                                        <p class="f-14 fw-bold text-right">{{selectedInstallmentPlanSummary.paidStatus}}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="f-14">Past Due</p>
                                    </td>
                                    <td>
                                        <p class="f-14 fw-bold text-right">
                                            ${{selectedInstallmentPlanSummary.pastDueAmount | number:'1.2-2'}}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="f-14">No of day PastDue</p>
                                    </td>
                                    <td>
                                        <p class="f-14 fw-bold text-right">
                                            #{{selectedInstallmentPlanSummary.numberOfDaysPastDue}}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="f-14">Next Payment Amount</p>
                                    </td>
                                    <td>
                                        <p class="f-14 fw-bold text-right">
                                            ${{selectedInstallmentPlanSummary.nextPaymentAmount | number:'1.2-2'}}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="f-14">Next Due Date</p>
                                    </td>
                                    <td>
                                        <p class="f-14 fw-bold text-right"
                                            *ngIf="(selectedInstallmentPlanSummary.nextPaymentDate)">
                                            {{selectedInstallmentPlanSummary.nextPaymentDate | dateFormat}}
                                        </p>
                                        <p class="f-14 fw-bold text-right"
                                            *ngIf="(!selectedInstallmentPlanSummary.nextPaymentDate)">
                                            --
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="f-14">Total Payoff</p>
                                    </td>
                                    <td>
                                        <p class="f-14 fw-bold text-right">
                                            ${{selectedInstallmentPlanSummary.installmentPayOffAmount | number:'1.2-2'}}
                                        </p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div *ngIf="!hideLedgerSummary && (this.selectedInstallmentPlanSummary)" class="card ledger-differentiate-box" [ngClass]="{
                            'ledger-differentiate-position': isLedgerDetailsCollapsed && !this.selectedPatientTreatmentInstallmentPlan.practiceInsuranceCompanyId,
                            ' position-relative ledger-colour': !isLedgerDetailsCollapsed ,
                            'position-relative ledger-colour': this.selectedPatientTreatmentInstallmentPlan.practiceInsuranceCompanyId
                            }">
                            <div class="mt-2">
                                <div class="btn btn-sm btn-icon  wid-30 hei-25 bg-info-card btn-success"></div> <span
                                    class="ms-3">Payment</span>
                            </div>
                            <div class="mt-2">
                                <div class="btn btn-sm btn-icon  wid-30 hei-25 bg-info-card purple"></div><span
                                    class="ms-3">Refund</span>
                            </div>
                            <div class="mt-2">
                                <div class="btn btn-sm btn-icon  wid-30 hei-25 bg-info-card btn-warning"></div><span
                                    class="ms-3">Credit Adjustment</span>
                            </div>
                            <div class="mt-2">
                                <div class="btn btn-sm btn-icon  wid-30 hei-25 bg-info-card pink"></div><span
                                    class="ms-3">Debit
                                    Adjustment</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <div *ngIf="isWalletLedger" printRemove>
            <h5 class="f-14 mb-2 col-2">Wallet Ledger</h5>
            <div class="d-flex flex-wrap gap-2">
                <ng-container *ngFor="let options of this.walletOptions; let i = index">
                    <div class="btn h-35 d-flex align-items-center" (click)="selectWalletLedger(options)"
                        [ngClass]="(this.selectdWalletOptions?.bindValue! == options.bindValue ) ? 'btn-primary' : 'btn-light-primary'">
                        <div class="text-truncate me-1" style="max-width: 180px;" title="{{options.bindLabel}}">
                            {{options.bindLabel}} </div> -
                        <b class="f-16 ms-1">${{options.walletAmount | number:'1.2-2' }}</b>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="col-lg-12 mt-2" *ngIf="!hideLedgerSummary" printRemove>
            <button class="btn btn-sm btn-light-secondary float-end" (click)="printLedger()"><i
                    class="fa fa-solid fa-print me-2"></i>Print</button>
        </div>

        <div class="table-responsive mt-2" printRemove>
            <table class="table row-border table-hover payment-dashboard customized-spacing-table ledger-table">
                <thead>
                    <tr class="bg-primary">
                        <th>
                            <div class="d-flex justify-content-between align-items-center">
                                DATE</div>
                        </th>
                        <th>
                            <div class="d-flex justify-content-between align-items-center">DESCRIPTION
                                <span class="d-flex flex-column position-relative"></span>
                            </div>
                        </th>
                        <th>
                            <div class="d-flex justify-content-between align-items-center"> METHOD
                                <span class="d-flex flex-column position-relative"></span>
                            </div>
                        </th>
                        <th>
                            <div class="d-flex justify-content-between align-items-center">CHARGE/<br>ADJUSTMENT
                                <span class="d-flex flex-column position-relative"></span>
                            </div>
                        </th>
                        <th>
                            <div class="d-flex justify-content-between align-items-center">
                                PAYMENT <span class="d-flex flex-column position-relative"></span></div>
                        </th>
                        <th>
                            <div class="d-flex justify-content-between align-items-center">PRINCIPAL
                                <span class="d-flex flex-column position-relative"></span>
                            </div>
                        </th>
                        <th>
                            <div class="d-flex justify-content-between align-items-center">INTEREST
                            </div>
                        </th>
                        <th>
                            <div class="d-flex justify-content-between align-items-center">BALANCE
                            </div>
                        </th>
                    </tr>
                    <tr class="spacer-row">
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let ledger of this.selectedPatientTreatmentContractLedger; let i = index">
                        <tr *ngIf="ledger.transactionDescription != creditDebitPlan.creditPaymentPlan 
                        && ledger.transactionDescription != creditDebitPlan.debitPaymentPlan" class="main-row">
                            <td>
                                <div class="d-flex align-items-center">
                                    <div class="d-flex justify-content-center align-items-center me-2" printRemove>
                                        <i (click)="isShowLedgerTableRow(ledger)"
                                            class="cursor-pointer f-24 fa {{ ledger.showLedgerSubRow ? 'fa-caret-down' : 'fa-caret-right' }} fa-solid"></i>
                                    </div>
                                    <h6 class="h6 mb-0 f-w-300 f-16 mt-2">{{ledger.transactionDate | dateFormat}}</h6>
                                </div>
                            </td>
                            <td>
                                <div class="d-flex">
                                    <h6 *ngIf="(!ledger.practiceInsuranceCompanyId || selectedPatientTreatmentInstallmentPlan.practiceInsuranceCompanyId || ledger.transactionCategory === 'Adjustment' || ledger.transactionDescription)"
                                        class="h6 mb-0 f-w-300 f-16 mt-2 wid-200 text-truncate">
                                        {{ledger.transactionDescription}}
                                    </h6>
                                    <h6 *ngIf="(ledger.practiceInsuranceCompanyId) && !selectedPatientTreatmentInstallmentPlan.practiceInsuranceCompanyId && ledger.transactionCategory !== 'Adjustment' && !ledger.transactionDescription"
                                        class="h6 mb-0 f-w-300 f-16 mt-2 wid-200 text-truncate">
                                        {{ledger.practiceInsuranceCompanyName}}
                                    </h6>
                                    <div printRemove
                                        *ngIf="(!ledger.practiceInsuranceCompanyId || selectedPatientTreatmentInstallmentPlan.practiceInsuranceCompanyId || ledger.transactionCategory === 'Adjustment') && ledger.transactionDescription.length > 16">
                                        <div ngxTippy="{{ledger.transactionDescription}}">
                                            <div class="btn btn-light-secondary wid-35 hei-35 d-flex justify-content-center align-items-center 
                                                img-radius ms-2 cursor-pointer">
                                                <i class="fa fa-regular fa-eye f-16"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div printRemove
                                        *ngIf="ledger.practiceInsuranceCompanyId && !selectedPatientTreatmentInstallmentPlan.practiceInsuranceCompanyId && ledger.transactionCategory !== 'Adjustment' && !ledger.transactionDescription">
                                        <div
                                            *ngIf="ledger.practiceInsuranceCompanyName && ledger.practiceInsuranceCompanyName.length > 16">
                                            <div ngxTippy="{{ledger.practiceInsuranceCompanyName}}">
                                                <div class="btn btn-light-secondary wid-35 hei-35 d-flex justify-content-center align-items-center 
                                                    img-radius ms-2 cursor-pointer">
                                                    <i class="fa fa-regular fa-eye f-16"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <h6 class="h6 mb-0 f-w-300 f-16 mt-2 wid-70 text-truncate" title="{{ledger.paymentMethod}}">{{ledger.paymentMethod == 'CreditOrDebitCard' ?
                                    'Card': ledger.paymentMethod == 'CashierCheck' ?
                                    'Check':ledger.paymentMethod }}</h6>
                            </td>
                            <td>
                                <h6 class="h6 mb-0 f-w-300 f-16 mt-2"
                                    *ngIf="ledger.transactionCategory==='Charge' || ledger.transactionCategory==='Adjustment'">
                                    <ng-container *ngIf="ledger.transactionType === 'Debit'">
                                        <span
                                            [ngClass]="ledger.transactionCategory==='Adjustment' ? 'text-pink' : '' ">${{ledger.transactionAmount
                                            | number:'1.2-2'}}</span>
                                    </ng-container>
                                    <ng-container *ngIf="ledger.transactionType === 'Credit'">
                                        <span
                                            [ngClass]="ledger.transactionCategory==='Adjustment' ? 'text-warning' : '' ">(${{ledger.transactionAmount
                                            | number:'1.2-2'}})</span>
                                    </ng-container>
                                </h6>
                                <h6 class="h6 mb-0 f-w-300 f-16 mt-2"
                                    *ngIf="ledger.transactionCategory!='Charge' && ledger.transactionCategory!='Adjustment'">
                                    $0.00
                                </h6>
                            </td>
                            <td>
                                <h6 class="h6 mb-0 f-w-300 f-16 mt-2"
                                    *ngIf="(ledger.transactionCategory=='Payment' || ledger.transactionCategory=='Refund')">
                                    <ng-container *ngIf="ledger.transactionType === 'Debit'">
                                        <span class="text-purple">${{ledger.transactionAmount | number:'1.2-2'}}</span>
                                    </ng-container>
                                    <ng-container *ngIf="ledger.transactionType === 'Credit'">
                                        <span class="text-success">(${{ledger.transactionAmount |
                                            number:'1.2-2'}})</span>
                                    </ng-container>
                                </h6>
                                <h6 class="h6 mb-0 f-w-300 f-16 mt-2"
                                    *ngIf="(ledger.transactionCategory!='Payment' && ledger.transactionCategory!='Refund')">
                                    $0.00</h6>
                            </td>
                            <td>
                                <h6 class="h6 mb-0 f-w-300 f-16 mt-2" *ngIf="ledger.principletransactionAmount!=0">
                                    <ng-container *ngIf="ledger.transactionType === 'Debit'">
                                        ${{ledger.principletransactionAmount | number:'1.2-2'}}
                                    </ng-container>
                                    <ng-container *ngIf="ledger.transactionType === 'Credit'">
                                        (${{ledger.principletransactionAmount | number:'1.2-2'}})
                                    </ng-container>
                                </h6>
                                <h6 class="h6 mb-0 f-w-300 f-16 mt-2" *ngIf="ledger.principletransactionAmount===0">
                                    $0.00</h6>
                            </td>
                            <td>
                                <h6 class="h6 mb-0 f-w-300 f-16 mt-2" *ngIf="ledger.interestTransactionAmount!=0">
                                    <ng-container *ngIf="ledger.transactionType === 'Debit'">
                                        ${{ledger.interestTransactionAmount | number:'1.2-2'}}
                                    </ng-container>
                                    <ng-container *ngIf="ledger.transactionType === 'Credit'">
                                        (${{ledger.interestTransactionAmount | number:'1.2-2'}})
                                    </ng-container>
                                </h6>
                                <h6 class="h6 mb-0 f-w-300 f-16 mt-2" *ngIf="ledger.interestTransactionAmount===0">$0.00
                                </h6>
                            </td>
                            <td>
                                <h6 class="h6 mb-0 f-w-300 f-16 mt-2">
                                    <ng-container *ngIf="ledger.balance >=0">
                                        ${{ledger.balance | number:'1.2-2'}}
                                    </ng-container>
                                    <ng-container *ngIf="ledger.balance <0">
                                        (${{-ledger.balance | number:'1.2-2'}})
                                    </ng-container>
                                </h6>
                            </td>
                        </tr>
                        <tr *ngIf="ledger.showLedgerSubRow == true" class="table-subrow">
                            <td colspan="8">
                                <div class="d-flex">
                                    <div class="me-4">
                                        <div>
                                            <h5 class="h5 f-16 fw-bold">USER INFO:</h5>
                                            <div class="d-flex align-items-center">
                                                <div printRemove
                                                    class="wid-35 hei-35 img-radius bg-primary text-white d-flex align-items-center justify-content-center">
                                                    {{ ledger.createdBy | initials}}
                                                </div>
                                                <span class="ms-2 ledger-table-col">{{ledger.createdBy}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="me-4 ledger-table-col">
                                        <h5 class="h5 f-16 fw-bold">NOTES:</h5>
                                        <h6 class="h6 mb-0 f-w-300 f-16"> {{ledger.notes}}</h6>
                                    </div>
                                    <div class="me-4">
                                        <div>
                                            <h5 class="h5 f-16 fw-bold">CATEGORY:</h5>
                                            <h6 class="h6 mb-0 f-w-300 f-16"> {{ledger.transactionCategory}}</h6>
                                        </div>
                                    </div>                                   
                                    <div class="me-4">
                                        <div *ngIf="ledger.transactionCategory==='Payment'">
                                            <h5 class="h5 f-16 fw-bold">CONFIRMATION #: </h5>
                                            <h6 class="h6 mb-0 f-w-300 f-16" title="{{ ledger.transactionId }}"
                                                *ngIf="(ledger.transactionId)">
                                                ...{{ ledger.transactionId.toString().slice(-5) }}</h6>
                                            <h6 class="h6 mb-0 f-w-300 f-16" *ngIf="(!ledger.transactionId)">
                                                {{ ledger.patientPaymentTransactionId }}</h6>
                                        </div>
                                    </div>
                                    <div class="me-4" *ngIf="ledger.fileUploadId">
                                        <div>
                                            <h5 class="h5 f-16 fw-bold"></h5>
                                            <p class="mb-0 text-primary cursor-pointer d-flex align-items-center" (click)="downloadDocument(ledger.fileUploadId, ledger.documentName)">
                                                <i title="download" class="fa fa-solid fa-download f-16 me-2"></i>Download EOB
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="spacer-row" *ngIf="ledger.transactionDescription != creditDebitPlan.creditPaymentPlan 
                            && ledger.transactionDescription != creditDebitPlan.debitPaymentPlan">
                            <td></td>
                        </tr>
                    </ng-container>
                </tbody>
                <tfoot class="">
                    <tr>
                        <td>Total</td>
                        <td></td>
                        <td></td>
                        <td>
                            <ng-container *ngIf="this.totalCharge >=0 && !this.isWalletLedger">
                                ${{this.totalCharge | number:'1.2-2'}}
                            </ng-container>
                            <ng-container *ngIf="this.totalCharge <0 && !this.isWalletLedger">
                                (${{this.totalCharge | number:'1.2-2'}})
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="!this.isWalletLedger">
                                (${{this.totalPaymentCollected | number:'1.2-2'}})
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="this.totalPrincipal >=0 && !this.isWalletLedger">
                                ${{this.totalPrincipal | number:'1.2-2'}}
                            </ng-container>
                            <ng-container *ngIf="this.totalPrincipal <0 && !this.isWalletLedger">
                                (${{this.totalPrincipal | number:'1.2-2'}})
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="this.totalInterest >=0 && !this.isWalletLedger">
                                ${{this.totalInterest | number:'1.2-2'}}
                            </ng-container>
                            <ng-container *ngIf="this.totalInterest <0 && !this.isWalletLedger">
                                (${{this.totalInterest | number:'1.2-2'}})
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="this.totalInterest >=0">
                                ${{this.totalBalance | number:'1.2-2'}}
                            </ng-container>
                            <ng-container *ngIf="this.totalInterest <0">
                                (${{this.totalBalance | number:'1.2-2'}})
                            </ng-container>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>

        <!-- Print Ledger Table Section-->
        <div class="col-lg-12 p-0 printOnly">
            <app-contract-ledger-print [LedgerDetails]="this.selectedPatientTreatmentContractLedger"
                [creditDebitPlan]="this.creditDebitPlan" [isWalletLedger]="this.isWalletLedger" [totalCharge]="this.totalCharge"
                [totalPrincipal]="this.totalPrincipal" [totalInterest]="this.totalInterest" [totalBalance]="this.totalBalance"
                [totalPaymentCollected]="this.totalPaymentCollected"
                [selectedPatientTreatmentInstallmentPlan]="this.selectedPatientTreatmentInstallmentPlan"></app-contract-ledger-print>
        </div>

    </div>

</ng-template>