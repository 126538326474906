import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { insuranceVerificationCategory } from 'src/app/features/dashboard/constant/dashboard.constant';
import { PatientSummaryModel } from 'src/app/features/patient/store/patient-summary/patient-summary.type';
import { deviceWidth } from 'src/app/features/shared/constant/shared-constant';
import { ScreenSizeService } from 'src/app/features/shared/services/screen-size.service';
import { AlertService } from 'src/app/features/shared/utils/alert.service';
import { AuthService } from 'src/app/features/user/services/authentication/auth.service';
import { patientDashboardMessage } from '../../constant/patient-dashboard-api-asset';

@Component({
  selector: 'app-patient-search-formstatus',
  templateUrl: './patient-search-formstatus.component.html',
})

export class PatientSearchFormstatusComponent {

  @Input() patientInfo?: PatientSummaryModel;
  @Input() fromTreatmentPlan = false;
  @Output() onClickIconEvent = new EventEmitter<string>();
  @Input() tippyZIndex = 1040;

  isTablet = false;
  insuranceVerificationCategory = insuranceVerificationCategory;
  isPatientUser = false;

  constructor(
    private store: Store, 
    private screenSizeService: ScreenSizeService,
    private alertService: AlertService,
    private authService: AuthService
  ) { 
    this.isPatientUser = this.authService.getIsPatientUserRoleFromToken();
  }

  async onClickIcon(name: string) {
    if (name === "TreatmentContract" && this.isPatientUser === true &&
      (!this.patientInfo?.hasPaymentPlan && !this.patientInfo?.hasTreatmentContract)) {
      await this.alertService.warningAlert(patientDashboardMessage.noPaymentPlanWarning)
      return;
    }  
    this.onClickIconEvent.emit(name)
  }

  getMedicalHistoryTooltip() {
    if (this.patientInfo?.medicalHistoryStatus === 'Valid')
      return 'Medical History'
    else if (!this.patientInfo?.medicalHistoryStatus || this.patientInfo?.medicalHistoryStatus === 'NotAvailable')
      return 'Yet To Complete'
    else if (this.patientInfo?.medicalHistoryStatus === 'Expired')
      return 'Expired'
    else
      return 'Invalid'
  }

  ngOnInit() {
    this.screenSizeService.screenWidth$.subscribe((screenInfo) => {
      if (screenInfo[0] <= deviceWidth.tablet) {
        this.isTablet = true;
        this.tippyZIndex = 1020;
      } else {
        this.isTablet = false;
        this.tippyZIndex = 1040;
      }
    });
  }
}