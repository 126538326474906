import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as signalR from '@microsoft/signalr';

import { environment } from 'src/environments/environment';
import {
    processMarkAsReadEvent,
    processPhoneCallEvent,
    processReceivedChatEvent,
    processSendChatEvent,
    scheduleEventReceived,
    signalRConnectionError, signalRConnectionStopped,
    signalRConnectionSuccess, startSignalRConnection,
    startSignalRReconnecting
} from '../store/signalr.action';
import { DateUtilService } from 'src/app/features/shared/services/date-util.service';
import { signalRConnectionStatus, signalRConnectionMessages, appointmentSignalREvent } from '../../core/constant/signalr.constant';
import { AuthService } from 'src/app/features/user/services/authentication/auth.service';

@Injectable({ providedIn: 'root' })

export class SignalrService {

    scheduleHubConnection!: signalR.HubConnection;
    practiceId!: number;
    constructor(private store: Store, private dateUtilService: DateUtilService, private authService: AuthService) {
        this.practiceId = this.authService.getPracticeIDFromToken();
        this.createConnection();
    }
   
    private createConnection() {
        const signalRUrl: string = environment.application.api_base_signalr_url;

        if (!this.scheduleHubConnection) {
            this.scheduleHubConnection = new signalR.HubConnectionBuilder()
                //.configureLogging(signalR.LogLevel.Debug)
                .withUrl(`${signalRUrl}signalr`, {
                    skipNegotiation: true,
                    transport: signalR.HttpTransportType.WebSockets,
                })
                .withAutomaticReconnect([0, 1000, 2000, 3000, 5000, 10000, 15000, 30000])
                //.withAutomaticReconnect([0, 2000, 10000, 30000]) yields the default behavior
                .build();

        }


        console.log("connection status :" + this.scheduleHubConnection.state)
    }

    setupConnectionHandlers = () => {
        this.scheduleHubConnection.onreconnected(() => this.handleReconnected());
        this.scheduleHubConnection.onreconnecting((error) => this.handleReconnecting(error));
        this.scheduleHubConnection.onclose((error) => this.handleConnectionClosed(error));
    }

    private handleReconnected = () => {
        this.store.dispatch(signalRConnectionSuccess({ date: this.dateUtilService.GetApplicationCurrentDateTime() }))
        console.log(signalRConnectionStatus.connected.name);
    }

    private handleReconnecting = (error: any) => {
        console.log(`${signalRConnectionMessages.reconnectingError} ${error}`);
        this.store.dispatch(startSignalRReconnecting(error))
        console.log(signalRConnectionMessages.reconnecting);
    }

    private handleConnectionClosed = (error: any) => {
        console.log(`$${signalRConnectionMessages.closingError} ${error}`);
        console.log(signalRConnectionMessages.disconnected);
        if (error)
            this.store.dispatch(signalRConnectionError(error))
        else
            this.store.dispatch(signalRConnectionStopped({ date: this.dateUtilService.GetApplicationCurrentDateTime() }))
    }

    startConnectionIfDisconnected = (): boolean => {
        if (this.scheduleHubConnection.state === signalR.HubConnectionState.Disconnected ||
            this.scheduleHubConnection.state === signalR.HubConnectionState.Disconnecting) {
            this.store.dispatch(startSignalRConnection())
            return true;
        }
        return false;
    }

    processPracticeScheduleChangedEvent() {
        this.scheduleHubConnection.on(appointmentSignalREvent, (model: any) => {
            if(this.practiceId == 2) {
                console.log('Received SignalR Event: ', model);
            }
            this.store.dispatch(scheduleEventReceived({ model: model, date: this.dateUtilService.GetApplicationCurrentDateTime() }));
        });
    }

    processReceivedCallEvent() {
        this.scheduleHubConnection.on("ProcessReceivedCallEvent", (model: any) => {
            if ((<any>window).electron ) {
                //(<any>window).electron.sendNotification('Incoming Call Alert', 'Name: ' + model.PatientName + '\n' + 'Phone: ' + model.PatientPhone);
            }
            //this.store.dispatch(processPhoneCallEvent({ event: 'ReceivedCall', callState: model }))
        });
    }

    processAnsweredCall() {
        this.scheduleHubConnection.on("ProcessAnsweredCallEvent", (model: any) => {
            //this.store.dispatch(processPhoneCallEvent({ event: 'AnsweredCall', callState: model }))
        });
    }

    processCompletedCall() {
        this.scheduleHubConnection.on("ProcessCompletedCallEvent", (model: any) => {
            //this.store.dispatch(processPhoneCallEvent({ event: 'CompletedCall', callState: model }))
        });
    }
    
    processReceivedMessage() {
        this.scheduleHubConnection.on("ProcessReceivedSMSEvent", (model) => {
            this.store.dispatch(processReceivedChatEvent({ event: 'ReceivedSMS', receivedChatState: model }))
            if ((<any>window).electron) {
                (<any>window).electron.sendNotification('Text Received', 'Name: ' + model.PatientName + '\n' + 'Phone: ' + model.PatientPhone + '\n' + 'Message: ' + model['gcm.notification.body']);
            }
        });
    }

    processSendMessage() {
        this.scheduleHubConnection.on("ProcessSendSMSEvent", (model) => {
            this.store.dispatch(processSendChatEvent({ event: 'SendSMS', receivedChatState: model }))
            if ((<any>window).electron) {
                (<any>window).electron.sendNotification('Text Received', 'Name: ' + model.PatientName + '\n' + 'Phone: ' + model.PatientPhone + '\n' + 'Message: ' + model['gcm.notification.body']);
            }
        });
    }

   
    processMarkAsRead() {
        this.scheduleHubConnection.on("ProcessMarkAsReadEvent", (model) => {
            this.store.dispatch(processMarkAsReadEvent({ event: 'MarkAsRead', receivedChatState: model }))
            if ((<any>window).electron) {
                (<any>window).electron.sendNotification('Text Received', 'Name: ' + model.PatientName + '\n' + 'Phone: ' + model.PatientPhone + '\n' + 'Message: ' + model['gcm.notification.body']);
            }
        });
    }

    stopSignalREventConnection() {
        this.scheduleHubConnection.off("ProcessReceivedCallEvent");
        this.scheduleHubConnection.off("ProcessAnsweredCallEvent");
        this.scheduleHubConnection.off("ProcessCompletedCallEvent");
        this.scheduleHubConnection.off("ProcessReceivedSMSEvent");
        this.scheduleHubConnection.off("ProcessSendSMSEvent");
        this.scheduleHubConnection.off("ProcessMarkAsReadEvent");
        this.scheduleHubConnection.off("processpracticeschedulechangedevent");
    }

}