import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import { Store } from '@ngrx/store';

import { AuthenticationAPIService } from '../../api/authentication-api.service';
import { applicationStorageKeys, roles } from '../../constant/user-role-constant';
import { AppLocalStorageService } from 'src/app/features/shared/services/app-local-storage.service';
import { AppSessionStorageService } from 'src/app/features/shared/services/app-session-storage.service';
import { liveLocationStorageKey } from 'src/app/features/patient-live-status/constant/patient-live-status-constant';
import { logInSuccess } from '../../store/actions/authentication.action';
import { PracticeUserRoleAPIService } from 'src/app/features/practice/api/practice-role-api.service';
import { PracticeFeaturePermissionService } from '../practice-feature-permission-service';
import { forkJoin } from 'rxjs';

@Injectable(
  { providedIn: 'root' }
)
export class AuthService {

  constructor(private authAPIService: AuthenticationAPIService, private appLocalStorageService: AppLocalStorageService,
    private appSessionStorageService: AppSessionStorageService,
    private store: Store,private practiceFeaturePermissionService:PracticeFeaturePermissionService,
    private practiceUserRoleAPIService: PracticeUserRoleAPIService) 
    {
      //if user is already logged in then set the user in store
      const authUser = JSON.parse(localStorage.getItem('authUser') ?? "{}");
      if (authUser.valid) {
        this.store.dispatch(logInSuccess({ authUser }));
      }
    }

  getToken() {
    //todo:localstorage to be replaced with AppLocalStorageService
    const authUser = JSON.parse(localStorage.getItem('authUser') ?? "{}");
    return authUser.accessToken;
  }

  getTokenContext() {
    const accessToken = this.getToken();
    return (accessToken) ? jwt_decode(accessToken) : {};
  }
  
  login(username: string, password: string, loginLocationId: number) {
    return this.authAPIService.login(username, password).pipe(
      map((user) => {
        this.appLocalStorageService.setItem(applicationStorageKeys.loginUserLocationId, loginLocationId.toString());
        localStorage.setItem('authUser', JSON.stringify(user));
        return user;
      })
    );
  }

  setUserRolesAndPermission() {
    return this.practiceUserRoleAPIService.getLoggedInUserPermissions(this.getCurrentPracticeId(),this.getUserRoleGuidIdFromToken()).pipe(
      map((permissions) => {
        localStorage.setItem(applicationStorageKeys.loggedInUserPermission, JSON.stringify(permissions));
        return permissions;
      })
    );
  }

  setUserPermissionAndPracticeFeatures() {
    const practiceId = this.getCurrentPracticeId();
    return forkJoin({
      userPermissions: this.setUserRolesAndPermission(),
      practicePermissions: this.practiceFeaturePermissionService.setPracticeFeaturesAndPermission(practiceId),
    }).pipe(
      map(({ userPermissions, }) => {
        return userPermissions;
      })
    );
  }

  getUserRolesAndPermission() {
    const authUserPermission = JSON.parse(localStorage.getItem(applicationStorageKeys.loggedInUserPermission) ?? "{}");
    return authUserPermission;
  }

  validateEmail(username: string) {
    return this.authAPIService.validateEmail(username).pipe(
      map((user) => {
        return user;
      })
    );
  }

  logout() {
    return this.authAPIService.logout().pipe(
      map(() => {
        //todo:localstorage to be replaced with AppLocalStorageService
        localStorage.clear();
        //todo:session storage service to be replaced with SessionStorageService
        sessionStorage.clear();
      })
    );
  }

  refreshToken() {
    const authUser = JSON.parse(localStorage.getItem('authUser') ?? "{}");
    return this.authAPIService.refreshToken(authUser.accessToken, authUser.refreshToken).pipe(
      map((token) => {
        const newAuthUser = JSON.parse(localStorage.getItem('authUser') ?? "{}");
        newAuthUser.accessToken = token.accessToken;
        newAuthUser.refreshToken = token.refreshToken;
        localStorage.setItem('authUser', JSON.stringify(newAuthUser));
        return token;
      })
    );
  }

  getPracticeIDFromToken() {
    return this.getCurrentPracticeId()
  }

  getEmailFromToken() {
    const accessToken: any = this.getTokenContext();
    return accessToken['Email']
  }

  getUserRoleFromToken() {
    const accessToken: any = this.getTokenContext();
    return accessToken['Role']
  }

  getUserRoleGuidIdFromToken() {
    const accessToken: any = this.getTokenContext();
    return accessToken['RoleGuid']
  }

  getUserPreferredNameFromToken() {
    const accessToken: any = this.getTokenContext();
    const firstName = accessToken['FirstName'];
    const lastName = accessToken['LastName'];
    return `${lastName}, ${firstName}`;
  }

  getUserPracticeIdFromToken() {
    return this.getCurrentPracticeId();
  }

  getIsPatientUserRoleFromToken() {
    const accessToken: any = this.getTokenContext();
    const loggedInUserRole = accessToken['Role'];
    return (loggedInUserRole === roles.patient || loggedInUserRole === roles.patientFRP);
  }

  getIsSelfChickinUserRoleFromToken() {
    const accessToken: any = this.getTokenContext();
    const loggedInUserRole = accessToken['Role'];
    return (loggedInUserRole === roles.selfCheckIn);
  }

  getLocationIdFromToken() {
    const liveLocationId = this.appSessionStorageService.getItem(liveLocationStorageKey);
    const loginUserLocationId = (this.appLocalStorageService.getItem(applicationStorageKeys.loginUserLocationId));
    return Number(liveLocationId) || Number(loginUserLocationId)
  }

  updateLocationIdToStorage(locationId: number) {
      this.appLocalStorageService.setItem(applicationStorageKeys.loginUserLocationId, locationId.toString());
  }

  getUserPracticeGUIDIdFromToken() {
    return this.getCurrentPracticeGuid();
  }

  getCurrentPracticeId() {
    const loggedinRole = this.getUserRoleFromToken();
    //todo:sessions storage should be replaced with appSessionStorageService
    const selectedPracticeIdBySA = sessionStorage.getItem(applicationStorageKeys.selectedPracticeIdBySA);
    if (loggedinRole === roles.superAdmin && selectedPracticeIdBySA) {
      return selectedPracticeIdBySA;
    }
    else {
      const accessToken: any = this.getTokenContext();
      return accessToken['PracticeId']
    }
  }

  getCurrentPracticeGuid() {
    const loggedinRole = this.getUserRoleFromToken();
    if (loggedinRole === roles.superAdmin) {
      //todo:sessions storage should be replaced with appSessionStorageService
      return sessionStorage.getItem(applicationStorageKeys.selectedPracticeGuidBySA)
    }
    else {
      const accessToken: any = this.getTokenContext();
      return accessToken['PracticeGuid']
    }
  }

  getIsSuperAdminRoleFromToken() {
    const accessToken: any = this.getTokenContext();
    const loggedInUserRole = accessToken['Role'];
    return (loggedInUserRole === roles.superAdmin);
  }

  getCurrentUserGuid() {
    const accessToken: any = this.getTokenContext();
    return accessToken['UserId']
  }

  getLoginLocationIdFromToken() {
    return Number(this.appLocalStorageService.getItem(applicationStorageKeys.loginUserLocationId));
  }
}
