import { Injectable } from '@angular/core';
import {
  AbstractControl, FormGroup, ValidationErrors,
  ValidatorFn, Validators
} from '@angular/forms';
import { of } from 'rxjs';
import { REGEX_PATTERN } from '../constant/shared-pattern-regex-asset';
import { AgeCalculatorService } from '../services/age-calculate-service';
import moment from 'moment';
import { DateUtilService } from '../services/date-util.service';

@Injectable()
export class CustomValidatorService {
  currentDate: Date;
  constructor(
    private ageCalculatorService: AgeCalculatorService,
    private dateUtilService: DateUtilService
    ) { 
      this.currentDate = this.dateUtilService.GetApplicationCurrentDateTime();
     }
  public static mobileNumberValidator(): ValidatorFn {
    const mobileNumberPattern: RegExp = REGEX_PATTERN.phoneNumber;
    return (control: AbstractControl): { [key: string]: any } | null => {
      const mobileNumber = control.value;
      const isValid = mobileNumberPattern.test(mobileNumber);
      if (!mobileNumber) return of(null);
      return of(isValid ? null : { 'mobileNumber': { value: mobileNumber } });
    };
  }

  public static taxIdFormatValidator(): ValidatorFn {
    const taxIdPattern: RegExp = REGEX_PATTERN.taxId;
    return (control: AbstractControl): { [key: string]: any } | null => {
      const taxId = control.value;
      const isValid = taxIdPattern.test(taxId);
      return of(isValid ? null : { 'taxId': { value: taxId } });
    };
  }

  public static dobFromatValidator(): ValidatorFn {
    const dobPattern: RegExp = REGEX_PATTERN.dobRegex;
    return (control: AbstractControl): { [key: string]: any } | null => {
      const dob = control.value;
      if (!dob) return of(null);
      const isValid = dobPattern.test(dob);
      return of(isValid ? null : { 'dob': { value: dob } });
    };
  }

  public static amountFormatValidation(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const amountPattern: RegExp = REGEX_PATTERN.amount;
      const value: string = control.value;
      const amount: number = parseFloat(value);
      if (amount > 99999) {
        return { maxAmountExceeded: '99999' };
      }
      if (value && Number.isNaN(amount)) {
        return { notNumeric: true };
      }
      if (value && !amountPattern.test(value)) {
        return { invalidAmount: true };
      }
      return null;
    };
  }

  public static percentageFormatValidation(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const percentage: RegExp = REGEX_PATTERN.percentage;
      const value: string = control.value;
      const amount: number = parseFloat(value);
      if (amount > 100) {
        return { maxPercentageExceeded: '100' };
      }
      if (value && Number.isNaN(amount) && !percentage.test(value)) {
        return { notNumericPercentage: true };
      }
      if (value && Number.isNaN(amount)) {
        return { notNumeric: true };
      }
      if (value && !percentage.test(value)) {
        return { invalidAmount: true };
      }
      return null;
    };
  }

  public static amountValidator(): ValidatorFn | null {
    return Validators.compose([
      CustomValidatorService.amountFormatValidation()
    ]);
  }

  public static percentageValidator(): ValidatorFn | null {
    return Validators.compose([
      CustomValidatorService.percentageFormatValidation()
    ]);
  }

  public static urlValidator(): ValidatorFn | null {
    return Validators.compose([
      Validators.pattern(REGEX_PATTERN.URL), Validators.maxLength(250)
    ]);
  }

  public static zipCodeValidator(): ValidatorFn | null {
    return Validators.compose([
      Validators.pattern(REGEX_PATTERN.zipcode), Validators.minLength(5), Validators.maxLength(5)
    ]);
  }

  public static emailValidator(): ValidatorFn | null {
    return Validators.compose([
      Validators.pattern(REGEX_PATTERN.email), Validators.maxLength(254)
    ]);
  }

  public static multiEmailValidator(): ValidatorFn | null {
    return Validators.compose([
      Validators.pattern(REGEX_PATTERN.multiemail), Validators.maxLength(1000)
    ]);
  }

  public static npiValidator(): ValidatorFn | null {
    return Validators.compose([
      Validators.pattern(REGEX_PATTERN.groupNpi), Validators.minLength(10), Validators.maxLength(10)
    ]);
  }

  public static phoneValidator(required: boolean | null = null) {
    return {
      validators: (required) ? [Validators.required] : [],
      asyncValidators: [CustomValidatorService.mobileNumberValidator()],
      updateOn: 'change'
    };
  }

  public static faxNumberValidator(required: boolean | null = null) {
    return {
      validators: (required) ? [Validators.required] : [],
      asyncValidators: [CustomValidatorService.mobileNumberValidator()],
      updateOn: 'change'
    }
  }

  public static taxIdValidator() {
    return {
      validators: Validators.required,
      asyncValidators: [CustomValidatorService.taxIdFormatValidator()],
      updateOn: 'change'
    }
  }
  public static removeMobileNumberFormatting(phonenumber: string): string | null {
    return phonenumber ? phonenumber?.replace(/\D/g, '') : null;
  }

  public static addMobileNumberFormatting(phonenumber: string): string | null {
    return phonenumber ? phonenumber.replace(REGEX_PATTERN.usMobile, '($1) $2-$3') : null;
  }

  public static setAndClearRequiredValidators(formGroup: FormGroup, requiredControls: string[], nonRequiredControls: string[]) {
    requiredControls.forEach(controlName => {
      const control = formGroup.get(controlName);
      control?.setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(100), this.trimLeadingWhitespaceValidator()]);
      control?.updateValueAndValidity();
    });
    nonRequiredControls.forEach(controlName => {
      const control = formGroup.get(controlName);
      control?.clearValidators();
      control?.updateValueAndValidity();
      formGroup.get(controlName)?.setValidators([Validators.minLength(1), Validators.maxLength(100), this.trimLeadingWhitespaceValidator()]);
      control?.updateValueAndValidity();
    });
  }

  public static phoneNumberExtensionValidator(): ValidatorFn | null {
    return Validators.compose([
      Validators.maxLength(4), Validators.pattern(REGEX_PATTERN.onlyNumeric)
    ]);
  }

  public static suffixValidator(): ValidatorFn | null {
    return Validators.compose([
      Validators.minLength(1), Validators.maxLength(100)
    ]);
  }

  public static cityValidator(): ValidatorFn | null {
    return Validators.compose([
      Validators.pattern(REGEX_PATTERN.city),
      Validators.minLength(1),
      Validators.maxLength(100)
    ]);
  }

  public static address1Validator(): ValidatorFn | null {
    return Validators.compose([
      Validators.minLength(1),
      Validators.maxLength(100)
    ]);
  }

  public static address2Validator(): ValidatorFn | null {
    return Validators.compose([
      Validators.minLength(1),
      Validators.maxLength(100)
    ]);
  }

  public static generalNameValidator(): ValidatorFn | null {
    return Validators.compose([
      Validators.minLength(1),
      Validators.maxLength(100),
      CustomValidatorService.trimLeadingWhitespaceValidator()
    ]);
  }

  public static personLastNameValidator(): ValidatorFn | null {
    return Validators.compose([
      Validators.minLength(1),
      Validators.maxLength(100),
      CustomValidatorService.trimLeadingWhitespaceValidator()
    ]);
  }

  public static personMiddleNameValidator(): ValidatorFn | null {
    return Validators.compose([
      Validators.minLength(1),
      Validators.maxLength(100),
      CustomValidatorService.trimLeadingWhitespaceValidator()
    ]);
  }

  public static personFirstNameValidator(): ValidatorFn | null {
    return Validators.compose([
      Validators.minLength(1),
      Validators.maxLength(100),
      CustomValidatorService.trimLeadingWhitespaceValidator()
    ]);
  }

  public static passwordValidator(): ValidatorFn | null {
    return Validators.compose([
      Validators.minLength(8),
      Validators.maxLength(20),
      CustomValidatorService.passwordPatternValidator()
    ]);
  }

  
private static passwordPatternValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const errors: ValidationErrors = {};
    const value = control.value;

    if (!/[a-z]/.test(value)) {
      errors['hasLowercase'] = true;
    }

    if (!/[A-Z]/.test(value)) {
      errors['hasUppercase'] = true;
    }

    if (!/\d/.test(value)) {
      errors['hasDigit'] = true;
    }

    if (/\s/.test(value)) {
      errors['noSpace'] = true;
    }

    return Object.keys(errors).length > 0 ? errors : null;
  };
}

  public static notesValidator(): ValidatorFn | null {
    return Validators.compose([
      Validators.minLength(1),
      Validators.maxLength(250)
    ]);
  }

  public static shortNotesValidator(): ValidatorFn | null {
    return Validators.compose([
      Validators.minLength(1),
      Validators.maxLength(100)
    ]);
  }

  public static trimLeadingWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value && typeof control.value === 'string') {
        const trimmedValue = control.value.trimLeft();
        if (trimmedValue !== control.value) {
          control.patchValue(trimmedValue);
          return { emptySpace: true };
        }
      }
      return null;
    };
  }

  public static suffixNameValidator(): ValidatorFn | null {
    return Validators.compose([
      Validators.minLength(1),
      Validators.maxLength(100),
      CustomValidatorService.trimLeadingWhitespaceValidator()
    ]);
  }

  public static descriptionValidator(): ValidatorFn | null {
    return Validators.compose([
      Validators.minLength(1),
      Validators.maxLength(100),
      CustomValidatorService.trimLeadingWhitespaceValidator()
    ]);
  }

  public ageValidatorFor18(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const dob = control.value;
      const birthDate: Date = moment(dob).toDate();
      const age = this.ageCalculatorService.calculateAge(birthDate);
      if (age.years < 18 && new Date(dob) < new Date()) {
        return { 'dobbelow18': true };
      }
      return null;
    };
  }

  /**
     * @deprecated use dateOutOfRange()
     */
  public static dateIsOutOfRange(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const date = control.value;
      if (new Date(date) > new Date() || new Date(date).getFullYear() < 1900) {
        return { 'outofrange': true };
      }
      return null;
    };
  }

  public dateOutOfRange(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const date = control.value;
      if (new Date(date) > this.currentDate || new Date(date).getFullYear() < 1900) {
        return { 'outofrange': true };
      }
      return null;
    };
  }

  public static eitherOneRequired(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const englishContent = control.get('practicePolicyEnglishContent');
      const spanishContent = control.get('practicePolicySpanishContent');

      if (!englishContent?.value && !spanishContent?.value) {
        return { 'eitherOneRequired': true };
      }

      return null;
    };
  }

  public static ssnValidator(): ValidatorFn | null {
    return Validators.compose([
      Validators.pattern(REGEX_PATTERN.ssnNumber),
    ]);
  }

  public static amountWithDecimalFormatValidator(): ValidatorFn | null {
    return Validators.compose([
      CustomValidatorService.validateAmountWithDecimalFormat()
    ]);
  }

  public static validateAmountWithDecimalFormat(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const amountPattern: RegExp = REGEX_PATTERN.amountDecimal;
      const value: string = control.value;
      if (value === '' || value === null) {
        return null;
      }
      if (/\.\./.test(value)) {
        return { multipleDots: true };
      }
      const amount: number = parseFloat(value);
      const stringValue: string = amount.toString();
      const decimalMatch = stringValue.split('.');
      if (decimalMatch.length > 0 && (decimalMatch[1] && decimalMatch[1].length > 2)) {
        return { invalidAmountDecimal: true };
      }
      if (amount > 99999.99) {
        return { maxAmountExceeded: '99999.99' };
      }
      if (value && Number.isNaN(amount)) {
        return { notNumeric: true };
      }
      if (value && !amountPattern.test(value)) {
        return { invalidAmount: true };
      }
      if (!amountPattern.test(value)) {
        return { invalidAmount: true };
      }
      return null;
    };
  }

  public static convertEmptyToNull(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value === '') {
        control.setValue(null, { emitEvent: false });
      }
      return null;
    };
  }

  public static authorizeRequiredValidator(): ValidatorFn | null {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const isAuthorize = control.value;
      if (isAuthorize === false) {
        return { 'authorize': true };
      }
      return null;
    };
  }

  public static ageValidator(): ValidatorFn | null {
    return Validators.compose([
      Validators.maxLength(2), Validators.pattern(REGEX_PATTERN.onlyNumeric)
    ]);
  }

  public static yearValidator(): ValidatorFn | null {
    return Validators.compose([
      Validators.minLength(4), Validators.pattern(REGEX_PATTERN.onlyNumeric), CustomValidatorService.yearFutureValidator()
    ]);
  }

  public static yearFutureValidator(): ValidatorFn | null {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const currentYear = new Date().getFullYear();
      const year = control.value;
      if (year && year.length === 4) {
        const parsedYear = parseInt(year, 10);
        if (parsedYear < 1900 || parsedYear > currentYear + 100) {
          return { 'yearoutofrange': true };
        }
      }
      return null;
    };
  }

  public static validateTimeRange(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const startTimeControl = control.get('startTime');
      const endTimeControl = control.get('endTime');
      const startTime = startTimeControl?.value;
      const endTime = endTimeControl?.value;

      if (startTime && endTime && startTime >= endTime) {
        return { timeRangeInvalid: true };
      }

      return null;
    };
  }

  public static numberValidator(): ValidatorFn | null {
    return Validators.compose([
      Validators.maxLength(2), Validators.pattern(REGEX_PATTERN.onlyNumeric)
    ]);
  }

  public static divisibleBy25(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      return value % 25 === 0 ? null : { divisibleBy25: true };
    };
  }

  validateRange = (startDate: string, endDate: string): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const startDateControl = control.get(startDate);
      const endDateControl = control.get(endDate);
      if (!startDateControl || !endDateControl) { return null; }
      const startDateValue = startDateControl.value;
      const endDateValue = endDateControl.value;
      if (startDateValue && endDateValue && new Date(startDateValue) > new Date(endDateValue)) {
        endDateControl.setErrors({ dateRangeInvalid: true });
        return { dateRangeInvalid: true };
      } else {
        if (endDateControl.errors && endDateControl.errors['dateRangeInvalid']) {
          endDateControl.setErrors(null);
        }
        return null;
      }
    };
  }

  public static allowOnlyTwoCharacters(chars: string[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let value = control.value;
      value = value ? value.toString().toUpperCase() : null;
      if (value !== 'Y' && value !== 'N') {
        return { invalidInput: true };
      }
      return null;
    };
  }

  public static validateAmountGreaterThanZero(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let value = control.value;
      if (!value ||  value <= 0 || !Validators.pattern(REGEX_PATTERN.onlyNumeric)) {
        return { invalidInput: true };
      }
      return null;
    };
  }

  public static accountNumberValidator(): ValidatorFn | null {
    return Validators.compose([
      Validators.pattern(REGEX_PATTERN.onlyNumeric), Validators.minLength(9), Validators.maxLength(18)
    ]);
  }

  public static routingNumberValidator(): ValidatorFn | null {
    return Validators.compose([
      Validators.pattern(REGEX_PATTERN.onlyNumeric), Validators.minLength(9), Validators.maxLength(10)
    ]);
  }

  public static compareEmailAddress(oldEmailAddress: string): ValidatorFn | null {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const newEmailAddress = control.value;
      if (oldEmailAddress === newEmailAddress) {
        return { 'isEmailAddressSame': true };
      }
      return null;
    };
  }

  public static licenseNumberValidator(): ValidatorFn | null {
    return Validators.compose([
      Validators.pattern(REGEX_PATTERN.onlyAlphanumeric), Validators.maxLength(20)
    ]);
  }

  public minDateValidation(minDate: Date): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const selectedDate = new Date(control.value).setHours(0, 0, 0, 0);
      if (new Date(selectedDate) < minDate) {
        return { 'minDateError': true };
      }
      return null;
    };
  }
}
