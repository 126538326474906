import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { AlertService } from 'src/app/features/shared/utils/alert.service';
import { getDocumentCategories } from '../../store/document-core-store/document-core.reducer';
import { selectDocumentCategories, selectDocumentCategoriesErrorMessage } from '../../store/document-core-store/document-core.selector';
import { DocumentCategory } from '../../store/document-core-store/document-core-state';
import { BaseDestroyComponent } from 'src/app/features/shared/components/base-destroy/base-destroy.component';
import { addDocuments, removeDocument, resetDocumentUploadState, uploadDocument } from '../../store/document-upload-store/document-upload.reducer';
import { documentMessageConstants, uploadType } from '../../constant/document-message.constant';
import { DocumentUpload } from '../../store/document-upload-store/document-upload.state';
import { PatientImage } from 'src/app/features/patient-image/store/patient-image-store/patient-image-info';
import { CommonUtilsService } from 'src/app/features/shared/utils/common-utils.service';

@Component({
  template: '',
})
export class BaseDocumentUploadComponent extends BaseDestroyComponent implements OnInit, OnDestroy {
  @Input() categoryName!: string;
  @Input() subcategoryName!: string;
  @Input() uploadDocumentType?: string = uploadType.document;
  @Input() patientImage?: PatientImage;
  documentCategories: DocumentCategory[] = [];
  documentCategoryInfo$ = this.store.select(selectDocumentCategories);
  documentCategoryError$ = this.store.select(selectDocumentCategoriesErrorMessage);

  constructor(protected store: Store, private alert: AlertService, private utilService:CommonUtilsService) {
    super();
  }

  ngOnInit(): void {
    if (this.uploadDocumentType === uploadType.toothImage) return;
    this.categoryName
    this.store.dispatch(getDocumentCategories());
    this.subscriptions$.add(this.documentCategoryInfo$.subscribe((categories: DocumentCategory[] | undefined) => this.bindDocumentCategory(categories)));
    this.subscriptions$.add(this.documentCategoryError$.subscribe((errorMessage: string | undefined) => this.showDocumentCategoryError(errorMessage)));
  }

  showDocumentCategoryError(errorMessage: string | undefined): void {
    if (errorMessage)
      this.alert.failureAlert(errorMessage);
  }

  bindDocumentCategory(categories: DocumentCategory[] | undefined): void {
    if (categories)
      this.documentCategories = categories;
  }

  uploadFile(file: File): void {
    if (!file) return;
    const files: File[] = [file];

    this.store.dispatch(addDocuments({ documents: files }));
    if (this.uploadDocumentType === uploadType.toothImage) {
      const formData: FormData = this.mapPatientToothImageFormData(file);
      this.store.dispatch(uploadDocument({ document: file, formData, uploadType: uploadType.toothImage }));
    }
    else {
      const categoryId = this.documentCategories.find(c => c.documentCategoryName === this.categoryName)?.documentCategoryId;
      const subcategoryId = this.documentCategories.find(c => c.documentSubCategoryName === this.subcategoryName)?.documentSubCategoryId;
      if (categoryId && subcategoryId) {
        const formData: FormData = this.mapDocumentFormData(file, categoryId, subcategoryId);
        this.store.dispatch(uploadDocument({ document: file, formData , uploadType: uploadType.document}));
      }
      else
        this.showDocumentCategoryError(documentMessageConstants.notFoundCategoryOrSubCategory);
    }
  }

  private mapDocumentFormData(file: File, categoryId: number, subcategoryId: number) {
    const formData: FormData = new FormData();
    formData.append('File', file);
    formData.append('DocumentCategoryId', categoryId.toString());
    formData.append('DocumentSubCategoryId', subcategoryId.toString());
    return formData;
  }

  private mapPatientToothImageFormData(file: File) {
    const formData: FormData = new FormData();
    if (!this.patientImage) return formData;
    formData.append('PatientImageId', this.patientImage.patientImageId.toString());
    formData.append('PatientId', this.patientImage.patientId.toString());
    formData.append('PracticeId', this.patientImage.practiceId.toString());
    formData.append('PatientImageSeriesId', this.patientImage.patientImageSeriesId.toString());
    formData.append('PatientImageTypeId', this.patientImage.patientImageTypeId.toString());
    formData.append('PatientImageDate', this.utilService.convertToServerDateTimeFormat(new Date));
    formData.append('IsActive', (true).toString());
    if (this.patientImage.patientImageId !== 0)
      formData.append('CroppedImage', file);
    else
      formData.append('OriginalImage', file);
    return formData;
  }

  removeUploadFile(file: DocumentUpload): void {
    this.store.dispatch(removeDocument({ documentUploaded: file }));
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store.dispatch(resetDocumentUploadState());
  }
}