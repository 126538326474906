export const schedulerViewType = {
    dayView: 'day',
    weekView: 'week'
}

export const schedulerViewDate = {
    currentDate: 'currentDate',
    currentView: 'currentView'
}

export const schedulerConstant = {
    appointmentClassSaveSuccess: "Appointment group created successfully.",
    appointmentClassUpdateSuccess: "Appointment group updated successfully.",
    appointmentTypeSaveSuccess: "Appointment type created successfully.",
    appointmentTypeUpdateSuccess: "Appointment type updated successfully.",
    appointmentTypeDeactivateWarning: 'We would like to confirm if you want to deactivate this appointment type. Are you sure?',
    appointmentTypeActivateSuccess: 'AppointmentType activated successfully.',
    appointmentTypeDeactivateSuccess: 'AppointmentType deactivated successfully.',
    appointmentTypeActivateWarning: 'We would like to confirm if you want to activate this appointment type. Are you sure?',
    examResultCreateSuccess: "Exam result created successfully.",
    examResultUpdateSuccess: "Exam result updated successfully.",
    examResultActivateSuccess: 'Exam result activated successfully.',
    examResultDeactivateSuccess: 'Exam result deactivated successfully.',
    examResultActivateWarning: 'We would like to confirm if you want to activate this exam result. Are you sure?',
    examResultDeactivateWarning: 'We would like to confirm if you want to deactivate this exam result. Are you sure?',
    appointmentProviderUpdateSuccess: "Appointment provider updated successfully.",
    onlineScheduleSaveSuccess: "Online Scheduling created successfully.",
    onlineScheduleUpdateSuccess: "Online Scheduling updated successfully.",
    onlinePatientReferralSuccess: "Online Patient Referral saved successfully"
}
export const schedulerNoteConstants = {
    scheduleNoteCreatedSuccess: "Note has been successfully created",
    scheduleNoteUpdatedSuccess: "Note has been successfully updated",
    scheduleNoteRemovedSuccess: "Note has been successfully removed.",
    scheduleNoteErrorMessage:"We are experiencing an error. Please try again.",
    scheduleNoteEndTimeGreaterThanStartTime: "End time must be greater than the start time.",
    scheduleNoteStartTimeWithinScheduleTime: "Start time must be within the schedule start and end time.",
    scheduleNoteEndTimeWithinScheduleTime: "End time must be within the schedule start and end time.",
}

export const commonEditorOptions = {
    showDataBeforeSearch: true,
    searchEnabled: true,
    minSearchLength: 2,
    searchTimeout: 300,
    placeholder: 'select or search',
    searchMode: 'startswith',
    dropDownOptions: {
        maxHeight: '250px',
    },
    wrapItemText: true
}

export const scheduleConstants = {
    appointmentCreateSuccess: 'The appointment has been successfully created.',
    appointmentUpdateSuccess: 'The appointment has been successfully updated.',
    appointmentCancelNotification: 'Is the appointment canceled by the patient?',
    appointmentRescheduleSuccess: 'The appointment has been successfully rescheduled.',
    appointmentDeleteNotification: 'Are you sure you want to delete this appointment?',
    appointmentNoShowConfirmationNotification: 'Are you sure you want to mark this appointment as No Show?',
    appointmentCancelSuccess: 'The appointment has been successfully cancelled.',
    appointmentDeleteSuccess: 'The appointment has been successfully deleted.',
    pastDateAppointmentCreationRestrictionAlert: "We are unable to schedule an appointment for a date that has already passed.",
    patientNameRequired: "The patient's name is required.",
    patientNameInvalid: "The patient's name is invalid.",
    provideRequired: "Doctor is required.",
    appointmentTypeRequired: "Appointment Type is required.",
    examToNonexamMessage: "You can't change an appointment type from an exam type to a non-exam type.",
    appointmentDurationRequired: "Appointment duration is required.",
    appointmentDurationMaximum: "Appointment duration should have a maximum of three digits only.",
    appointmentDurationNumeric: "Appointment duration should be in digits only.",
    appointmentStartDateRequired: "Appointment start date is required.",
    appointmentStartTimeRequired: "Appointment start time is required.",
    appointmentStatusRequired: "Appointment Status is required.",
    chairRequired: "Chair is required.",
    liveChairRequired: "Live Chair is required.",
    appointmentNotesMaximum: "That's a bit long! Please limit your input to 200 characters or fewer.",
    examCompletedValidation: "The appointment-associated exam's status is completed, so you cannot edit the appointment.",
    appointmentNoShowSuccessMessage: 'The appointment has been successfully marked as no show.',
    appointmentConfirmedSuccessMessage: 'The appointment has been successfully confirmed.',
    appointmentUnconfirmedSuccessMessage: 'The appointment has been successfully unconfirmed.',
    appointmentCheckInSuccessMessage: 'The patient has been successfully checked in.',
    appointmentCheckoutSuccessMessage: 'The patient has been successfully checked out.',
    slotValidationMessage: 'Before making an appointment or proceeding to a reservation, please select an available appointment.',
    appointmentDateValidationMessage: 'The appointment date must be today or any date in the future.',
    clipboardSameAppointmentWarningError: 'The clipboard already contains an appointment with the same Appointment ID. Unable to move.',
    clipboardAppointmentUpdateSuccess: 'The appointment has been successfully moved to clipboard.',
    clipboardAppointmentDeleteSuccess: 'The appointment has been successfully deleted from the clipboard.',
    customTemplateSaveSuccess: "Scheduler custom template created successfully.",
    customTemplateDeleteSuccess: "Scheduler custom template removed successfully.",
}

export const selfCheckInConstants = {
    selfCheckInConfirmationWarning: 'Are you sure you want to check in for your appointment?',
    selfCheckInSuccess: 'Thank you! Your appointment was check-in successfully.',
    selfCheckinBeginingMessage: 'You must enter at least two characters for your first name and last name to begin the search.',
    selfCheckinDobWarning: 'Your search criteria could not find a patient who has an appointment today. Please retry your search using other search criteria. Kindly provide the year of birth.',
    selfCheckinEmailWarning: 'Your search criteria could not find a patient who has an appointment today. Please retry your search using other search criteria. Kindly provide the valid Email Address.',
    selfCheckinNoRecordWarning: 'We could not find a matching appointment with the information provided. Please ensure that you enter at least two characters for both the first name and last name. Thank you for your understanding and cooperation.'
}

export const appointmentAction = {
    cancel: 'cancel',
    delete: 'delete',
    update: 'update',
    create: 'create',
    noShow: 'noShow',
    confirm: 'confirm',
    unconfirm: 'unconfirm',
    reschedule: 'reschedule',
    dragdrop: 'dragdrop',
    checkIn: 'checkIn',
    checkout: 'checkout',
    clipboardDrag: 'clipboardDrag',
    clipboardDrop: 'clipboardDrop',
    clipboardRemove: 'clipboardRemove'
}

export const scheduleStore = {
    patientSchedulerConfiguration: 'patientSchedulerConfiguration',
    schedulerConfiguration: 'schedulerConfiguration',
    patientappointments: 'patientappointments',
    appointments: 'appointments',
    schedulerConfigurationTemplates: 'schedulerConfigurationTemplates',
    patientSchedulerConfigurationTemplates: 'patientSchedulerConfigurationTemplates',
}

export const appointmentActionMenu = {
    cancelAppointment: 'Cancel Appointment',
    rescheduleAppointment: 'Reschedule Appointment',
    deleteAppointment: 'Delete Appointment',
    markAsNoShow: 'Mark as No Show',
    checkIn: 'Check-in',
    checkoutAppointment: 'Check-out',
    confirmUnconfirmAppointment: 'Confirm/Unconfirm Appointment',
    editAppointment: 'Edit Appointment',
    printAppointment: 'Print Appointment',
    navigateTreatmentCard: 'Treatment Card Summary',
    navigatePatientSummary: 'Patient Summary',
    navigateImageSummary: 'Image Summary',
    navigateContractSummary: 'Contract Summary',
    sendAppointmentDetail: 'Send Appointment Detail',
    addNotes: 'Add Notes',
    sendNotification: 'Send Notification',
    addNewCustomTemplate: 'Add New Template',
    removeCustomTemplate:'Remove Template'
}
export const onlineSchedulingUrlRoute = "online-scheduling/id"
export const scheduleStorageKey = "scheduleLocationId"
export const emailValidation = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
export const scheduleDateTime = {
    date: 'MM/dd/yyyy',
    time: 'hh:mm a'
}

export const appointmentSlotsSearchByOptions = [
    {
        name: 'Type',
        value: 'Appointment Type'
    },
    {
        name: 'Group',
        value: 'Appointment Group'
    }
]

export const genericScheduleParams = {
    startDate: "startDate",
    endDate: "endDate",
    practiceAppointmentGroupId: "practiceAppointmentGroupId",
    practiceAppointmentTypeId: "practiceAppointmentTypeId",
    patientAppointmentDuration: "patientAppointmentDuration"
}

export const appointmentDetailEmailSubject = "Appointment Information" 
export const appointmentDetailHtmlContent = `<div style="width: 550px; margin: 0 auto; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; color: #222; background-color: #f1f1f1; border: 0.5px solid #0667BA;">
<div style="padding: 20px 15px; color: #0667BA; padding-bottom: 0; text-align: center;"><h1 style="font-size: 30px; margin: 0; padding: 0; text-align: center; margin-bottom: 15px;">Appointment Information</h1><img style="width: 60%;" src="https://infinire.com/opms-appointment-reminder.png"></div>
<div style="padding: 20px 15px; background-color: #0667BA; color: #fff;">
    <p style="font-size: 20px; margin: 0; padding: 0; margin-bottom: 10px; font-weight: bold;">Hello {{patientName}}</p>
    <p style="margin: 0; padding: 0; margin-bottom: 15px; text-align: left; font-size: 16px;">
        <b>Practice Name:</b> {{practiceName}}<br>
        <b>Phone Number:</b> {{practicePhoneNumber}}
    </p>
    <!-- Patient details -->
    <p style="margin: 0; padding: 0; margin-bottom: 15px; text-align: left; font-size: 16px;">
        <b>Patient Detail</b><br>
        <b>Name:</b> {{patientName}}
    </p>
    <!-- Appointment details -->
    <p style="margin: 0; padding: 0; margin-bottom: 15px; text-align: left; font-size: 16px;">
        <b>Appointment Detail</b><br>
        <b>Date & Time:</b> {{patientAppointmentTime}}<br>
        <b>Type:</b> {{practiceAppointmentName}}<br>
        <b>Location:</b> {{patientAppointmentLocationName}}<br>
        <b>Duration:</b> {{patientAppointmentDuration}} minutes<br>
        <b>Status:</b> {{patientAppointmentStatusName}}
    </p>

    <!-- Closing message -->
    <div style="text-align: center; padding-top: 10px; border-top: 1px solid #fff;">
        <p style="margin: 0; padding: 0; margin-bottom: 10px; text-align: center;"><b>Thank you for choosing us!</b></p>
        <div style="clear: both;"></div>
        <div style="width: 100%; float: left; margin-top: 10px;">
            <div style="width: 30%; float: left; font-size: 14px;">
                <p style="margin: 0; padding: 0; text-align: left; font-size: 10px;">ID:<span style="margin: 0; padding: 0; margin-left: 5px; font-weight: bold;"><b>{{patientAppointmentId}}</b></span></p>
            </div>
            <div style="width: 70%; float: right;">
                <p style="margin: 0; padding: 0; margin-bottom: 5px; text-align: right;">Best regards,</p>
                <p style="margin: 0; padding: 0; text-align: right;"><b>{{practiceName}}</b></p>
            </div>
            <div style="clear: both;"></div>
        </div>
        <div style="clear: both;"></div>
    </div>
</div>
</div>`


export const patientAppointmentConfirmation = {
    confirmationMessage : "We are pleased to confirm your dental appointment with us. Say goodbye to waiting on hold or coordinating schedules over the phone. With just a few clicks, you've secured your visit. We look forward to helping you achieve a brighter, healthier smile!",
    appointmentUpdatedMessage : "Your appointment confirmation status has been updated.",
    noPendingAppointmentMessage: "Great to see that! All of your appointments have been confirmed. We appreciate you and thank you for your confirmation."
}