import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseDestroyComponent } from 'src/app/features/shared/components/base-destroy/base-destroy.component';
import { AlertService } from 'src/app/features/shared/utils/alert.service';
import { selectPracticeInfoErrorMessage, selectPracticePersonalCoreInfo } from '../../store/selector/practice-core-selector';
import { invokeGetPracticeCoreInfo } from '../../store/reducer/practice-core.reducer';
import { PracticePersonalInformation } from '../../store/state/practice-info';

@Component({
  selector: 'app-practice-logo',
  template: `
        <app-image-download
            [documentGuid]="practiceLogoGuid" [showUpload]="false" [showDownload]="false"
            defaultImagePath="assets/images/menuicon/practice_icon.png">
        </app-image-download>
  `
})
export class PracticeLogoComponent extends BaseDestroyComponent implements OnInit {
  @Input() practiceId!: number;
  constructor(private store: Store, private alert: AlertService) { super(); }
  isLoading: boolean | undefined;

  practiceInfo$ = this.store.select(selectPracticePersonalCoreInfo);
  stateEvent$ = this.store.select(selectPracticeInfoErrorMessage);
  practiceLogoGuid?: string;

  ngOnInit(): void {
    this.store.dispatch(invokeGetPracticeCoreInfo({ practiceId: this.practiceId }));
    this.subscriptions$.add(this.practiceInfo$.subscribe((data?: PracticePersonalInformation) => this.bindPersonalInformation(data)));
    this.subscriptions$.add(this.stateEvent$.subscribe((data) => this.handleStateEvent(data)));
  }

  bindPersonalInformation(data?: PracticePersonalInformation) {
    if (data) {
      this.practiceLogoGuid = data.practiceLogo
    }
  }

  async handleStateEvent(errorMessage: string | undefined) {
    if (errorMessage) {
      await this.alert.failureAlert(errorMessage)
    }
  }
}
