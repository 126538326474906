import { createReducer, on } from '@ngrx/store';
import {  PracticeUserState } from './practice-user.state';
import {
  invokePracticeUserLocation, invokePracticeUserLocationSuccess,
  invokePracticeUserLocationFailure, invokeCreatePracticeUser, invokeCreatePracticeUserSuccess, invokeCreatePracticeUserFailure,
  invokePracticeUserCloseErrorAlert, invokePracticeUserCloseSuccessAlert, invokeGetAllUsers, invokeGetAllUsersFailure,
  invokeGetAllUsersSuccess, invokeGetPracticeUserByEmail, invokeGetPracticeUserByEmailSuccess, invokeGetPracticeUserByEmailFailure,
  resetPracticeUserState, invokeUpdatePracticeUser, invokeUpdatePracticeUserSuccess, invokeUpdatePracticeUserFailure, invokeMarkAsActiveUser,
  invokeMarkAsActiveSuccess, invokeMarkAsActiveFailure as invokeMarkAsInActiveUserFailure, invokeUserForgotPassword, invokeUserForgotPasswordSuccess, invokeUserForgotPasswordFailure, invokeUserResetPassword, invokeUserResetPasswordSuccess, invokeUserResetPasswordFailure, invokeUserChangePassword, invokeUserChangePasswordSuccess, invokeUserChangePasswordFailure, invokeGetUserEmailByUserID, invokeGetUserEmailByUserIDSuccess, invokeGetUserEmailByUserIDFailure, invokeGeneratePasswordResetTokenByEmail, invokeGeneratePasswordResetTokenByEmailSuccess, invokeGeneratePasswordResetTokenByEmailFailure, invokeMarkAsInActiveUser, invokeMarkAsInActiveUserSuccess, invokeUpdateSignature, invokeUpdateSignatureSuccess, invokeUpdateSignatureFailure, invokeValidateEmail, invokeValidateEmailSuccess, invokeValidateEmailFailure, validateSecurityPin, validateSecurityPinSuccess, validateSecurityPinFailure, resetSecurityPinState,
  invokeGetUsersByRoles,
  invokeGetUsersByRolesSuccess,
  invokeGetUsersByRolesFailure
} from './practice-user.action';

export const initialState: PracticeUserState = {
  practiceUserRols: [],
  practiceUsers: [],
  isValidEmail: undefined,
  isValidSecurityPin: undefined,
  practiceUserStateEvent: {
    errorMessage: '',
    showErrorAlert: false,
    loading: false,
    isFormSubmitted: false,
    showSuccessAlert: false,
    isSignatureLoading: false,
    isValidatePinLoading: false
  },
  practiceUserLocations: [],
  practiceUser: {
    userId: "",
    prefix: "",
    firstName: "",
    middleName: "",
    lastName: "",
    suffix: "",
    userName: "",
    password: "",
    email: "",
    homePhone: "",
    mobilePhone: "",
    officePhone: "",
    userRoleGuid: "",
    userRole: "",
    userType: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    stateAbbreviation: "",
    zipcode: "",
    country: "",
    displayName: "",
    preferredName: "",
    isDefaultRole: false,
    providerNpi: "",
    primaryLocationId: 0,
    providerLicenseNumber: "",
    printedSignature: "",
    signatureImage: "",
    securityPin: "",
    providerPrintedSignature: "",
    providerPrimaryLocationId: ""
  },
  forgotPasswordResponse: {
    message: "",
    statusCode: 0
  },
  currentUserEmail: "",
  selectedUserEmail: ""
}

export const PracticeUserReducer = createReducer(
  initialState,

  //#region GetAllUsers
  on(invokeGetAllUsers, (state, action): PracticeUserState => ({
    ...state,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      loading: true
    }
  })),
  on(invokeGetAllUsersSuccess, (state, action): PracticeUserState => ({
    ...state,
    practiceUsers: action.users,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      loading: false
    }
  })),
  on(invokeGetAllUsersFailure, (state, action): PracticeUserState => ({
    ...state,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      errorMessage: action.errorMessage,
      showErrorAlert: true,
      loading: false
    }
  })),
  //#endregion

  //#region Get User By Email
  on(invokeGetPracticeUserByEmail, (state, action): PracticeUserState => ({
    ...state,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      isFormSubmitted: true
    }
  })),
  on(invokeGetPracticeUserByEmailSuccess, (state, action): PracticeUserState => ({
    ...state,
    practiceUser: Object.assign(action.practiceUser),
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      isFormSubmitted: false
    }
  })),
  on(invokeGetPracticeUserByEmailFailure, (state, action): PracticeUserState => ({
    ...state,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      errorMessage: action.errorMessage,
      showErrorAlert: true,
      isFormSubmitted: false
    }
  })),
  //#endregion

  //#region  UserLocation
  on(invokePracticeUserLocation, (state, action): PracticeUserState => ({
    ...state,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      isFormSubmitted: true
    }
  }))
  ,
  on(invokePracticeUserLocationSuccess, (state, action): PracticeUserState => ({
    ...state,
    practiceUserLocations: action.practiceUserLocations,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      isFormSubmitted: false
    }
  })),
  on(invokePracticeUserLocationFailure, (state, action): PracticeUserState => ({
    ...state,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      errorMessage: action.errorMessage,
      showErrorAlert: true,
      isFormSubmitted: false
    }
  })),
  //#endregion

  //#region  Create PracticeUser
  on(invokeCreatePracticeUser, (state, action): PracticeUserState => ({
    ...state,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      isFormSubmitted: true,
      successMessage: action.successMessage,
    }
  })),
  on(invokeCreatePracticeUserSuccess, (state, action): PracticeUserState => {
    const createdPracticeUsers =  state.practiceUsers.some(user =>
      action.practiceUser && user.email === action.practiceUser.email
    )
      ? state.practiceUsers.map(user =>
        user.email === action.practiceUser.email
          ? { ...user, ...action.practiceUser }
          : user
      )
      : [...state.practiceUsers, (action.practiceUser ? action.practiceUser : Object.assign(initialState.practiceUser))];
    const user = action.practiceUser ? Object.assign(action.practiceUser) : state.practiceUser;
    return {
      ...state,
      practiceUser: user,
      practiceUsers: createdPracticeUsers,
      practiceUserStateEvent: {
        ...state.practiceUserStateEvent,
        showSuccessAlert: action.practiceUser ? true : false,
        showErrorAlert: action.practiceUser ? false : true,
        errorMessage: action.practiceUser ? "" : "API Error",
        isFormSubmitted: false,
      }
    }
  }),
  on(invokeCreatePracticeUserFailure, (state, action): PracticeUserState => ({
    ...state,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      errorMessage: action.errorMessage,
      showErrorAlert: true,
      isFormSubmitted: false
    }
  })),

  //#endregion

  //#region  Update PracticeUser
  on(invokeUpdatePracticeUser, (state, action): PracticeUserState => ({
    ...state,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      isFormSubmitted: true,
      successMessage: action.successMessage,
    }
  })),
  on(invokeUpdatePracticeUserSuccess, (state, action): PracticeUserState => {
    const updatedPracticeUsers = state.practiceUsers.some(user =>
      action.practiceUser && user.email === action.practiceUser.email
    )
      ? state.practiceUsers.map(user =>
        user.email === action.practiceUser.email
          ? { ...user, ...action.practiceUser }
          : user
      )
      : [...state.practiceUsers, action.practiceUser];
    const user = action .practiceUser ?  Object.assign(action.practiceUser) : state.practiceUser; 
    return {
      ...state,
      practiceUser:user,
      practiceUsers: updatedPracticeUsers,
      practiceUserStateEvent: {
        ...state.practiceUserStateEvent,
        showSuccessAlert: action.practiceUser ? true : false,
        showErrorAlert:  action.practiceUser ? false : true,
        errorMessage: action.practiceUser ? "" : "API Error",
        isFormSubmitted: false,
      }
    }
  }),
  on(invokeUpdatePracticeUserFailure, (state, action): PracticeUserState => ({
    ...state,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      errorMessage: action.errorMessage,
      showErrorAlert: true,
      isFormSubmitted: false
    }
  })),

  //#endregion

  //#region  Activate PracticeUser
  on(invokeMarkAsActiveUser, (state, action): PracticeUserState => ({
    ...state,
    selectedUserEmail: action.email,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      successMessage: action.successMessage,
    }
  })),
  on(invokeMarkAsActiveSuccess, (state, action): PracticeUserState => {
    const updatedPracticeUsers = state.practiceUsers.some(user =>
      action.practiceUser && user.email === action.practiceUser.email
    )
      ? state.practiceUsers.map(user =>
        user.email === action.practiceUser.email
          ? { ...user, ...action.practiceUser }
          : user
      )
      : [...state.practiceUsers, action.practiceUser];
    return {
      ...state,
      practiceUsers: updatedPracticeUsers,
      practiceUserStateEvent: {
        ...state.practiceUserStateEvent,
        showSuccessAlert: true,
      }
    };
  }),
  on(invokeMarkAsInActiveUserFailure, (state, action): PracticeUserState => ({
    ...state,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      errorMessage: action.errorMessage,
      showErrorAlert: true,
    }
  })),
  //#endregion

  //#region  DeActivate PracticeUser
  on(invokeMarkAsInActiveUser, (state, action): PracticeUserState => ({
    ...state,
    selectedUserEmail: action.email,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      successMessage: action.successMessage,
    }
  })),
  on(invokeMarkAsInActiveUserSuccess, (state, action): PracticeUserState => {
    const updatedPracticeUsers = state.practiceUsers.some(user =>
      action.practiceUser && user.email === action.practiceUser.email
    )
      ? state.practiceUsers.map(user =>
        user.email === action.practiceUser.email
          ? { ...user, ...action.practiceUser }
          : user
      )
      : [...state.practiceUsers, action.practiceUser];
    return {
      ...state,
      practiceUsers: updatedPracticeUsers,
      practiceUserStateEvent: {
        ...state.practiceUserStateEvent,
        showSuccessAlert: true,
      }
    };
  }),
 
  on(invokeMarkAsInActiveUserFailure, (state, action): PracticeUserState => ({
    ...state,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      errorMessage: action.errorMessage,
      showErrorAlert: true,
    }
  })),
  //#endregion

  //#region   PracticeUser Forgot password
  on(invokeUserForgotPassword, (state, action): PracticeUserState => ({
    ...state,
    selectedUserEmail: action.email,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      successMessage: action.successMessage,
    }
  })),
  on(invokeUserForgotPasswordSuccess, (state, action): PracticeUserState => ({
    ...state,
    forgotPasswordResponse: Object.assign(action.forgotPassword),
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      showSuccessAlert: true,
    },
  })),
  on(invokeUserForgotPasswordFailure, (state, action): PracticeUserState => ({
    ...state,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      errorMessage: action.errorMessage,
      showErrorAlert: true,
    }
  })),
  //#endregion

  //#region   PracticeUser Reset password
  on(invokeUserResetPassword, (state, action): PracticeUserState => ({
    ...state,
    selectedUserEmail: action.resetPassword.email,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
    }
  })),
  on(invokeUserResetPasswordSuccess, (state, action): PracticeUserState => ({
    ...state,
    practiceUser: Object.assign(action.response),
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      showSuccessAlert: true,
    },
  })),
  on(invokeUserResetPasswordFailure, (state, action): PracticeUserState => ({
    ...state,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      errorMessage: action.errorMessage,
      showErrorAlert: true,
    }
  })),
  //#endregion

  //#region   PracticeUser Change password
  on(invokeUserChangePassword, (state, action): PracticeUserState => ({
    ...state,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      successMessage: action.successMessage,
    }
  })),
  on(invokeUserChangePasswordSuccess, (state, action): PracticeUserState => ({
    ...state,
    practiceUser: Object.assign(action.response),
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      showSuccessAlert: true,
    },
  })),
  on(invokeUserChangePasswordFailure, (state, action): PracticeUserState => ({
    ...state,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      errorMessage: action.errorMessage,
      showErrorAlert: true,
    }
  })),
  //#endregion

  //#region GetUser Email By UserID
  on(invokeGetUserEmailByUserID, (state, action): PracticeUserState => ({
    ...state,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      loading: true
    }
  })),
  on(invokeGetUserEmailByUserIDSuccess, (state, action): PracticeUserState => ({
    ...state,
    currentUserEmail: action.userEmail,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      loading: false
    }
  })),
  on(invokeGetUserEmailByUserIDFailure, (state, action): PracticeUserState => ({
    ...state,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      errorMessage: action.errorMessage,
      showErrorAlert: true,
      loading: false
    }
  })),
  //#endregion

  //#region Generate Password ResetToken ByEmail
  on(invokeGeneratePasswordResetTokenByEmail, (state, action): PracticeUserState => ({
    ...state,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
    }
  })),
  on(invokeGeneratePasswordResetTokenByEmailSuccess, (state, action): PracticeUserState => ({
    ...state,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
    }
  })),
  on(invokeGeneratePasswordResetTokenByEmailFailure, (state, action): PracticeUserState => ({
    ...state,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      errorMessage: action.errorMessage,
      showErrorAlert: true,
    }
  })),
  //#endregion

  //#region Update Signature
 on(invokeUpdateSignature, (state, action): PracticeUserState => ({
    ...state,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      isSignatureLoading : true,
    }
  })),
  on(invokeUpdateSignatureSuccess, (state, action): PracticeUserState => ({
    ...state,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      isSignatureLoading:false,
    }
  })),
  on(invokeUpdateSignatureFailure, (state, action): PracticeUserState => ({
    ...state,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      errorMessage: action.errorMessage,
      showErrorAlert: true,
      isSignatureLoading: false
    }
  })),
  //#endregion

   //#region Validate Email
 on(invokeValidateEmail, (state, action): PracticeUserState => ({
  ...state,
  practiceUserStateEvent: {
    ...state.practiceUserStateEvent,
    loading: true
  }
})),
on(invokeValidateEmailSuccess, (state, action: any): PracticeUserState => ({
  ...state,
  isValidEmail : action.response.isValid,
  practiceUserStateEvent: {
    ...state.practiceUserStateEvent,
    loading: false
  }
})),
on(invokeValidateEmailFailure, (state, action): PracticeUserState => ({
  ...state,
  isValidEmail: false,
  practiceUserStateEvent: {
    ...state.practiceUserStateEvent,
    showErrorAlert : false,
    loading: false
  }
})),
//#endregion

//#region Alert
on(invokePracticeUserCloseSuccessAlert, (state, action): PracticeUserState => ({
  ...state,
  selectedUserEmail: "",
  practiceUserStateEvent: {
    ...state.practiceUserStateEvent,
    showSuccessAlert: false,
    isFormSubmitted: false,
    loading: false,
  }
})),
on(invokePracticeUserCloseErrorAlert, (state, action): PracticeUserState => ({
  ...state,
  selectedUserEmail: "",
  practiceUserStateEvent: {
    ...state.practiceUserStateEvent,
    showErrorAlert: false,
    loading: false
  }
})),
on(resetPracticeUserState, (): PracticeUserState => initialState),
  //#endregion


  //#region Validate security pin
on(validateSecurityPin, (state): PracticeUserState => ({
  ...state,
  isValidSecurityPin:  undefined,
  practiceUserStateEvent: {
    ...state.practiceUserStateEvent,
    isValidatePinLoading: true,
    errorMessage: ""
  }
})),
on(validateSecurityPinSuccess, (state, action): PracticeUserState => ({
  ...state,
  isValidSecurityPin: action.isValidPin,
  practiceUserStateEvent: {
    ...state.practiceUserStateEvent,
    isValidatePinLoading: false,
    errorMessage: action.isValidPin ? "" : "Invalid pin"
  }
})),
on(validateSecurityPinFailure, (state, action): PracticeUserState => ({
  ...state,
  practiceUserStateEvent: {
    ...state.practiceUserStateEvent,
    isValidatePinLoading: false,
    showErrorAlert: true,
    errorMessage : action.errorMessage
  }
})),
on(resetSecurityPinState, (state, action): PracticeUserState => ({
  ...state,
  practiceUserStateEvent: {
    ...state.practiceUserStateEvent,
    isValidatePinLoading: false
  },
  isValidSecurityPin: initialState.isValidSecurityPin,
})),
  //#endregion

  on(invokeGetUsersByRoles, (state, action): PracticeUserState => ({
    ...state,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      loading: true
    }
  })),
  on(invokeGetUsersByRolesSuccess, (state, action): PracticeUserState => ({
    ...state,
    practiceUsers: action.users,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      loading: false
    }
  })),
  on(invokeGetUsersByRolesFailure, (state, action): PracticeUserState => ({
    ...state,
    practiceUserStateEvent: {
      ...state.practiceUserStateEvent,
      errorMessage: action.errorMessage,
      showErrorAlert: true,
      loading: false
    }
  })),
);