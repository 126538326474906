import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PatientLiveStatusSubscriptionService } from '../../patient-live-status/service/patient-live-status-subscription.service';
import { practiceGroupUnsubscribe, stopSignalRConnection } from 'src/app/infrastructure/signalr/store/signalr.action';
import { resetPracticeCoreState } from '../../practice/store/reducer/practice-core.reducer';
import { resetMenuState } from 'src/app/theme/store/menu/menu.actions';
import { resetSignalrStore } from 'src/app/infrastructure/signalr/store/signalr.action';
import { MenuService } from 'src/app/theme/store/menu/menu.service';
import { logout, logoutSuccess } from '../store/actions/authentication.action';
import { AppSessionStorageService } from '../../shared/services/app-session-storage.service';
import { AuthService } from './authentication/auth.service';
import { PatientCommunicationPopUpService } from '../../patient-communication/service/patient-communication-popup-service';
import { PatientCommunicationActionService } from '../../patient-communication/service/patient-communication-action-service';
import { resetChatState } from '../../patient-communication/store/chat-store/chat.action';
import { resetHabitsState } from '../../patient/store/habits/habits.action';
import { resetDentalHygieneState } from '../../patient/store/dental-hygienes/dental-hygienes.action';
import { resetallergiesState } from '../../patient/store/allergies/allergies.action';
import { resetMedicalConditionState } from '../../patient/store/medical-conditions/medical-conditions.action';
import { resetChiefComplaintState } from '../../patient/store/chief-complaints/chief-complaints.action';
import { resetLetterTemplate } from '../../letter-template/store/letter-configuration-store/letter-configuration.action';

@Injectable({ providedIn: 'root' })
export class LogoutService {
    constructor(
        private store: Store,
        private menuService: MenuService,
        private patientLiveStatusSubscriptionService: PatientLiveStatusSubscriptionService,
        private appSessionStorageService: AppSessionStorageService,
        private authService : AuthService,
        private patientCommunicationPopUpService : PatientCommunicationPopUpService,
        private patientCommunicationActionService : PatientCommunicationActionService
    ) { }

    sessionTimeout(): void {
        this.resetState();
        this.store.dispatch(logoutSuccess());
    }

    logOut(): void {
        this.store.dispatch(logout());
    }

    resetState(): void {
        this.patientLiveStatusSubscriptionService.unsubscribeSignalRLocation();
        this.appSessionStorageService.clear();
        this.store.dispatch(stopSignalRConnection());
        this.store.dispatch(resetPracticeCoreState());
        this.store.dispatch(resetMenuState());
        this.store.dispatch(resetSignalrStore());
        this.store.dispatch(practiceGroupUnsubscribe({ practiceGuid: this.authService.getUserPracticeGUIDIdFromToken() }))
        this.menuService.resetMainMenuItems();
        this.patientCommunicationPopUpService.resetOpenedPopups();
        this.patientCommunicationActionService.resetActivePatientCommunicationPopups();
        this.store.dispatch(resetChatState());
        this.store.dispatch(resetHabitsState());
        this.store.dispatch(resetDentalHygieneState());
        this.store.dispatch(resetallergiesState());
        this.store.dispatch(resetMedicalConditionState());
        this.store.dispatch(resetChiefComplaintState());
        this.store.dispatch(resetLetterTemplate());
    }
}