<div class="custom_stepper" *ngIf="steps && steps.length > 0">
    <div class="custom_stepper_head" [ngClass]="{'header_nav_disabled': disableNavigation}">

        <ng-container *ngFor="let step of steps; let i = index">
            <div class="custom_stepper_head_item" (click)="!disableNavigation && headerStepNavigation(step, i)"
                [class.active_step]="activeStepIndex === i" [class.completed_step]="activeStepIndex > i">
                <div class="custom_stepper_item_indicator">{{i + 1}}</div>
                <div class="custom_stepper_head_item_details">
                    <div class="step_label">{{ step.title }}</div>
                </div>
            </div>
        </ng-container>

    </div>
    <div class="stepper-content p-2 mt-4">

        <ng-container *ngIf="templates[activeStepIndex];">
            <ng-container *ngTemplateOutlet="templates[activeStepIndex]"></ng-container>
        </ng-container>

        <div class="col-lg-12 p-0">
            <ng-container *ngFor="let action of steps[activeStepIndex].actions">
                <button class="btn"
                    [ngClass]="[action.color || 'btn-primary', action.value !== 'back' ? 'float-end ms-2' : '']"
                    (click)="handleStepsAction(steps[activeStepIndex], action)">
                    {{action.text}}
                </button>
            </ng-container>

        </div>

    </div>
</div>