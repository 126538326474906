import { MenuItem } from '../store/menu/menu.state';
import { moduleName,featureName,operationName } from 'src/app/features/user/constant/user-role-constant';

export const ExamReportSubMenu: MenuItem[] = [
    {
        id: 1,
        title: 'Conversion',
        type: 'item',
        url: 'report/exam/conversion',
        classes: 'nav-item'
    },
    {
        id: 2,
        title: 'Result',
        type: 'item',
        url: 'report/exam/results',
        classes: 'nav-item'
    },
    {
        id: 3,
        title: 'Referral',
        type: 'item',
        url: 'report/exam/referral',
        classes: 'nav-item'
    }
]

export const ScheduleReportSubMenu: MenuItem[] = [
    {
        id: 1,
        title: 'Summary',
        type: 'item',
        url: 'report/appointment/summary',
        classes: 'nav-item'
    },
    {
        id: 2,
        title: 'Detail',
        type: 'item',
        url: 'report/appointment/detail',
        classes: 'nav-item'
    },
    {
        id: 3,
        title: 'Detail with Payment',
        type: 'item',
        url: 'report/appointment/detail-with-payment',
        classes: 'nav-item'
    },
    {
        id: 4,
        title: 'Missing In Action',
        type: 'item',
        url: 'report/appointment/missing-in-action',
        classes: 'nav-item'
    },
    {
        id: 5,
        title: 'Do Not Pursue',
        type: 'item',
        url: 'report/appointment/do-not-pursue',
        classes: 'nav-item'
    }
]

export const PaymentReportSubMenu: MenuItem[] = [
    {
        id: 1,
        title: 'Detail',
        type: 'item',
        url: 'report/collection/detail',
        classes: 'nav-item',
    },
    {
        id: 2,
        title: 'Daily Invoice',
        type: 'item',
        url: 'report/collection/daily-invoice',
        classes: 'nav-item',
    },
    {
        id: 3,
        title: 'Day Summary',
        type: 'item',
        url: 'report/collection/day-sheet',
        classes: 'nav-item',
    }
]
export const ContractReportSubMenu: MenuItem[] = [
    {
        id: 1,
        title: 'Starts',
        type: 'item',
        url: 'report/contract/starts',
        classes: 'nav-item'
    }
]

export const FinaceReportSubMenu: MenuItem[] = [
    {
        id: 1,
        title: 'Delinquent',
        type: 'item',
        url: 'report/finance/delinquent',
        classes: 'nav-item'
    },
    {
        id: 2,
        title: 'ACH Pending',
        type: 'item',
        url: 'report/finance/achpending',
        classes: 'nav-item'
    },
    {
        id: 3,
        title: 'Balance Sheet',
        type: 'item',
        url: 'report/finance/balancesheet',
        classes: 'nav-item'
    },
    {
        id: 4,
        title: 'Adjustment',
        type: 'item',
        url: 'report/finance/adjustment',
        classes: 'nav-item'
    }
]

export const ReceivableReportSubMenu: MenuItem[] = [
    {
        id: 1,
        title: 'Patient A/R',
        type: 'item',
        url: 'report/receivable/patient',
        classes: 'nav-item'
    },
    {
        id: 2,
        title: 'Insurance A/R',
        type: 'item',
        url: 'report/receivable/insurance',
        classes: 'nav-item'
    }
     
]

export const CommunicationReportSubMenu: MenuItem[] = [
    {
        id: 1,
        title: 'Patient Reminder',
        type: 'item',
        url: 'report/communication/patient',
        classes: 'nav-item'
    },
    {
        id: 2,
        title: 'Appointment Reminder',
        type: 'item',
        url: 'report/communication/appointment',
        classes: 'nav-item'
    },
    {
        id: 3,
        title: 'Payment/Pastdue Reminder',
        type: 'item',
        url: 'report/communication/payment',
        classes: 'nav-item'
    },
    {
        id: 4,
        title: 'Email/Text details',
        type: 'item',
        url: 'report/communication/overall',
        classes: 'nav-item'
    }
     
]

export const ReportSubMenuItems: MenuItem[] = [
    {
        id: 1,
        title: 'Dashboard',
        type: 'item',
        url: 'report/dashboard',
        classes: 'nav-item',
        icon: 'assets/images/menuicon/sub-menu/report.svg',
        hiddenFunction:  (userRole, userAuthService) => !userAuthService?.
            hasPermission([{ moduleName:moduleName.report,featureName:featureName.report,operationName:operationName.all }]),
    },
    {
        id: 2,
        title: 'Exam',
        type: 'item',
        url: 'report/heartbeat',
        classes: 'nav-item',
        icon: 'assets/images/menuicon/sub-menu/exam-report.svg',
        children: ExamReportSubMenu,
        hiddenFunction:  (userRole, userAuthService) => !userAuthService?.
            hasPermission([{ moduleName:moduleName.report,featureName:featureName.report,operationName:operationName.all }]),
    },
    {
        id: 3,
        title: 'Appointment',
        type: 'item',
        url: 'report/appointment',
        classes: 'nav-item',
        icon: 'assets/images/menuicon/sub-menu/schedule-report.svg',
        children: ScheduleReportSubMenu,
        hiddenFunction:  (userRole, userAuthService) => !userAuthService?.
            hasPermission([{ moduleName:moduleName.report,featureName:featureName.report,operationName:operationName.all }]),
    },
    {
        id: 4,
        title: 'Collection',
        type: 'item',
        url: 'report/collection',
        classes: 'nav-item',
        icon: 'assets/images/menuicon/sub-menu/payment-report.svg',
        children: PaymentReportSubMenu,
        hiddenFunction:  (userRole, userAuthService) => !userAuthService?.
            hasPermission([{ moduleName:moduleName.report,featureName:featureName.report,operationName:operationName.all }]),
    },
    {
        id: 5,
        title: 'Contract',
        type: 'item',
        url: 'report/contract',
        classes: 'nav-item',
        icon: 'assets/images/menuicon/sub-menu/contract-report.svg',
        children: ContractReportSubMenu,
        hiddenFunction:  (userRole, userAuthService) => !userAuthService?.
            hasPermission([{ moduleName:moduleName.report,featureName:featureName.report,operationName:operationName.all }]),
    },
    {
        id: 6,
        title: 'Finance',
        type: 'item',
        url: 'report/finance',
        classes: 'nav-item',
        icon: 'assets/images/menuicon/sub-menu/finance-report.svg',
        children: FinaceReportSubMenu,
        hiddenFunction: (userRole, userAuthService) => !userAuthService?.
            hasPermission([{ moduleName: moduleName.report, featureName: featureName.report, operationName: operationName.all }]),
    },
    {
        id: 7,
        title: 'Receivable',
        type: 'item',
        url: 'report/receivable',
        classes: 'nav-item',
        icon: 'assets/images/menuicon/sub-menu/receivable-report.svg',
        children: ReceivableReportSubMenu,
        hiddenFunction: (userRole, userAuthService) => !userAuthService?.
            hasPermission([{ moduleName: moduleName.report, featureName: featureName.report, operationName: operationName.all }]),
    },
    {
        id: 8,
        title: 'Comm',
        type: 'item',
        url: 'report/communication',
        classes: 'nav-item',
        icon: 'assets/images/menuicon/sub-menu/receivable-report.svg',
        children: CommunicationReportSubMenu,
        hiddenFunction: (userRole, userAuthService) => !userAuthService?.
            hasPermission([{ moduleName: moduleName.report, featureName: featureName.report, operationName: operationName.all }]),
    }
]


