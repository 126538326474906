import { createReducer, on } from "@ngrx/store";
import { PatientSearchState } from "../state/patient-search-state";
import {
    bulkPatientSearchStateDestroy,
    bulkSelectedPatients,
    closeErrorAlert, invokedBulkBasicPatientSearchAPI, invokedBulkEmailPatientSearchAPI, invokedBulkPatientSearchFilter, invokedPatientNameAPI,
    invokedPatientSearchAPI, invokedPatientSearchFilter,
    patientBulkBasicPatientSearchAPISuccess,
    patientBulkEmailSearchAPIFailure,
    patientBulkEmailSearchAPISuccess,
    patientNameAPIFailure, patientNameAPISuccess,
    patientSearchAPIFailure, patientSearchAPISuccess,
    patientSearchStateDestroy,
    practiceLocationAPIFailure, practiceLocationAPISuccess,
    practicePatientStatusAPIFailure, practicePatientStatusAPISuccess
} from "../actions/patient-search.action";
import { patientSearchTypes } from "../../constant/patient-dashboard-api-asset";

export const initialState: PatientSearchState = {
    patientSearchResult: [],
    patientSearchFilter: {
        patientId: 0,
        firstName: "",
        lastName: "",
        patientName: "",
        patientPreferredName: "",
        patientDOB: "",
        patientPrimaryPhone: "",
        patientPrimaryEmailAddress: "",
        patientFinancialResponsiblePersonFirstName: "",
        patientFinancialResponsiblePersonLastName: "",
        patientFinancialResponsiblePerson: "",
        patientStatusId: "",
        selectedPatientStatusId: [],
        practiceLocationId: "",
        selectedPracticeLocations: [],
        SortOrder: "",
        SortColumn: "",
        tackAfter: "",
        pageSize: "",
    },
    patientSearchStateEvent: {
        errorMessage: '',
        showErrorAlert: false,
        loading: false,
        showSuccessAlert: false,
        patientNameloading: false,
    },
    bulkEmailPatientSearchResult: [],
    bulkEmailPatientSearchFilter: {
        patientId: 0,
        firstName: "",
        lastName: "",
        patientName: "",
        patientPreferredName: "",
        patientDOB: "",
        patientPrimaryPhone: "",
        patientPrimaryEmailAddress: "",
        patientFinancialResponsiblePersonFirstName: "",
        patientFinancialResponsiblePersonLastName: "",
        patientFinancialResponsiblePerson: "",
        patientStatusId: "",
        selectedPatientStatusId: [],
        practiceLocationId: "",
        selectedPracticeLocations: [],
        SortOrder: "",
        SortColumn: "",
        tackAfter: "",
        pageSize: "",
    },
    bulkEmailPatientSearchStateEvent: {
        errorMessage: '',
        showErrorAlert: false,
        loading: false,
        showSuccessAlert: false,
        patientNameloading: false,
    },
    patientNameSearchResult: [],
    practicePatientStatus: [],
    practiceLocation: [],
}

export const patientSearchReducer = createReducer(
    initialState,
    on(invokedPatientSearchFilter, (state, action): PatientSearchState => ({
        ...state,
        patientSearchFilter: action.patientSearchFilter
    })),
    on(invokedPatientSearchAPI, (state, action): PatientSearchState =>
        updateStateEvent(state,action.pageIdentifier, { loading: true })
    ),
    on(patientSearchAPISuccess, (state, action): PatientSearchState => ({
        ...state,
        ...updateStateEvent(state,action.pageIdentifier, { showErrorAlert: false, loading: false }),
        patientSearchResult: action.patientSearchResult,
    })),
    on(patientSearchAPIFailure, (state, action): PatientSearchState =>
        updateStateEvent(state,action.pageIdentifier, { errorMessage: action.errorMessage, showErrorAlert: true, loading: false })
    ),
    on(invokedBulkPatientSearchFilter, (state, action): PatientSearchState => ({
        ...state,
        bulkEmailPatientSearchFilter: action.patientSearchFilter
    })),
    on(invokedBulkEmailPatientSearchAPI, (state, action): PatientSearchState =>
        updateStateEvent(state,action.pageIdentifier, { loading: true })
    ),
    on(patientBulkEmailSearchAPISuccess, (state, action): PatientSearchState => ({
        ...state,
        ...updateStateEvent(state,action.pageIdentifier, { showErrorAlert: false, loading: false }),
        bulkEmailPatientSearchResult: action.patientSearchResult,
    })),
    on(invokedBulkBasicPatientSearchAPI, (state, action): PatientSearchState =>
        updateStateEvent(state,action.pageIdentifier, { selectAllLoading: true })
    ),
    on(patientBulkBasicPatientSearchAPISuccess, (state, action): PatientSearchState => ({
        ...state,
        ...updateStateEvent(state,action.pageIdentifier, { showErrorAlert: false, selectAllLoading: false }),
        bulkEmailBasicPatientSearchResult: action.bulkEmailBasicPatientSearchResult,
    })),
    on(patientBulkEmailSearchAPIFailure, (state, action): PatientSearchState =>
        updateStateEvent(state,action.pageIdentifier, { errorMessage: action.errorMessage, showErrorAlert: true, loading: false,selectAllLoading:false })
    ),
    on(invokedPatientNameAPI, (state, action): PatientSearchState => ({
        ...state,
        ...updateStateEvent(state,action.pageIdentifier, { patientNameloading: true }),
        patientSearchFilter: action.patientSearchFilter
    })),
    on(patientNameAPISuccess, (state, action): PatientSearchState => ({
        ...state,
        ...updateStateEvent(state, action.pageIdentifier, { showErrorAlert: false, patientNameloading: false }),
        patientNameSearchResult: action.patientNameSearchResult,
    })),
    on(patientNameAPIFailure, (state, action): PatientSearchState =>
        updateStateEvent(state, action.pageIdentifier, { errorMessage: action.errorMessage, showErrorAlert: true, patientNameloading: false })
    ),
    on(practicePatientStatusAPISuccess, (state, action): PatientSearchState => ({
        ...state,
        ...updateStateEvent(state, action.pageIdentifier, { showErrorAlert: false }),
        practicePatientStatus: action.practicePatientStatus,
    })),
    on(practicePatientStatusAPIFailure, (state, action): PatientSearchState =>
        updateStateEvent(state, action.pageIdentifier, { errorMessage: action.errorMessage, showErrorAlert: true })
    ),
    on(practiceLocationAPISuccess, (state, action): PatientSearchState => ({
        ...state,
        ...updateStateEvent(state, action.pageIdentifier, { showErrorAlert: false }),
        practiceLocation: action.practiceLocation,
    })),
    on(practiceLocationAPIFailure, (state, action): PatientSearchState =>
        updateStateEvent(state, action.pageIdentifier, { errorMessage: action.errorMessage, showErrorAlert: true })
    ),
    on(closeErrorAlert, (state, action): PatientSearchState =>
        updateStateEvent(state, action.pageIdentifier, { showErrorAlert: false })
    ),
    on(bulkSelectedPatients, (state, action): PatientSearchState => ({
        ...state,
        selectedPatients: action.patients
    })),
    on(patientSearchStateDestroy, (): PatientSearchState => initialState),
    on(bulkPatientSearchStateDestroy, (state): PatientSearchState =>({ 
        ...state,
        bulkEmailPatientSearchResult: [],
        bulkEmailPatientSearchFilter: initialState.bulkEmailPatientSearchFilter,
        bulkEmailPatientSearchStateEvent: initialState.bulkEmailPatientSearchStateEvent,
        bulkEmailBasicPatientSearchResult: undefined
    })),
);

function updateStateEvent(state: PatientSearchState, pageIdentifier = patientSearchTypes.patientSearch, changes: Partial<PatientSearchState['patientSearchStateEvent'] | PatientSearchState['bulkEmailPatientSearchStateEvent']>) {
    
    if (pageIdentifier === patientSearchTypes.patientSearch) {
        return {
            ...state,
            patientSearchStateEvent: {
                ...state.patientSearchStateEvent,
                ...changes
            }
        };
    } else if (pageIdentifier === patientSearchTypes.bulkEmailPatientSearch) {
        return {
            ...state,
            bulkEmailPatientSearchStateEvent: {
                ...state.bulkEmailPatientSearchStateEvent,
                ...changes
            }
        };
    }
    return state;
}
