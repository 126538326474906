import { environment } from "src/environments/environment";

const PRACTICE_API_BASE_URL = environment.application.api_base_practice_url;
const PATIENT_API_BASE_URL = environment.application.api_base_patient_url;
const REPORT_API_BASE_URL = environment.application.api_base_report_api_url;

function practiceApiUrl(endpoint: string) {
    return `${PRACTICE_API_BASE_URL}${endpoint}`;
}

function patientApiUrl(endpoint: string) {
    return `${PATIENT_API_BASE_URL}${endpoint}`;
}

function reportApiUrl(endpoint: string) {
    return `${REPORT_API_BASE_URL}${endpoint}`;
}

export const patientDashboardApiAssert = {
    getPatientStatuses: practiceApiUrl("Practice/GetPatientStatuses"),
    getPracticeLocations: practiceApiUrl("PracticeLocation/GetPracticeLocations"),
    getPatientByName: patientApiUrl("Patient/GetPatientByName"),
    searchPatient: patientApiUrl("Patient/SearchPatient"),
    bulkPatientSearch: reportApiUrl("Report/BulkPatientSearch"),
    bulkBasicPatientSearch: reportApiUrl("Report/BulkBasicPatientSearch"),
    getPatientById: patientApiUrl("Patient/GetPatientById"),
}

export const patientDashboardNavigateAsset = {
    goToPatientSummary: 'patientinfo/patientdashboard',
    goToPatientLoginInfo: 'patientinfo/patientLoginInfo',
    patientPaymentPlanWidet: 'patientinfo/patientLoginInfo/paymentplanwidget',
}

export const PatientMenuItem =
{
    patientSummaryPageTitle : 'Patient Summary',
    patientSummaryPageIdProperty: 'PatientId',
    patientTxCardPageTitle : 'Patient Treatment Card',
    patientCardPageTitle : 'Patient Card',
    communicationTitle : 'Patient Communications'
}

export const NAVIGATE_URL_CONSTANTS = {
    PATIENT_SUMMARY: 'patientsummary',
    TX_CARD_SUMMARY: 'txcardsummary',
    IMAGE_SUMMARY: 'imagesummary',
    CONTRACT_SUMMARY: 'contractsummary',
    GO_TO_PATIENT_SUMMARY: 'goToPatientSummary',
    COMMUNICATION_SUMMARY: 'communicationsummary'
  };

  export const patientSearchMessage = {
    newSearchConfimation:'Are you sure you want to select new patients?',
  }

  export const patientDashboardMessage = {
    noPaymentPlanWarning: 'No payment plan or service contract found for this patient.',
  }

  export const patientSearchTypes = {
    patientSearch: 'patientSearch',
    bulkEmailPatientSearch: 'bulkEmailPatientSearch'
  }

  export const bulkPatientSearchFilterTypes = [
    {filterName: 'Appointment', filterValue:1},
    {filterName: 'Past Due', filterValue:2}
  ]

  
