import { Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import {
  EMPTY, catchError, concatMap, map, mergeMap, of
} from "rxjs";
import { Store } from "@ngrx/store";
import {
  invokedBulkBasicPatientSearchAPI,
  invokedBulkEmailPatientSearchAPI,
  invokedPatientNameAPI, invokedPatientSearchAPI,
  invokedPracticeLocationAPI, invokedPracticePatientStatusAPI, bulkBasicPatientSearchAPIFailure, patientBulkBasicPatientSearchAPISuccess, patientBulkEmailSearchAPIFailure, patientBulkEmailSearchAPISuccess, patientNameAPIFailure,
  patientNameAPISuccess, patientSearchAPIFailure, patientSearchAPISuccess,
  practiceLocationAPIFailure, practiceLocationAPISuccess, practicePatientStatusAPIFailure,
  practicePatientStatusAPISuccess
} from "../actions/patient-search.action";
import { PatientSearchAPIService } from "../../api/patientsearch-api.service";
import { selectPracticeLocation, selectPracticePatientStatus } from "../selectors/patient-search.selector";
import { patientSearchTypes } from "../../constant/patient-dashboard-api-asset";

@Injectable()
export class PatientSearchEffect {

  constructor(private actions$: Actions,
              private patientSearchAPIService: PatientSearchAPIService,
              private store: Store) { }

  loadPatientSearchAPI$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(invokedPatientSearchAPI),
      concatMap(({ patientSearchFilter, pageIdentifier }) => {
        return this.patientSearchAPIService.getPatientSearchData(patientSearchFilter).pipe(
          map(patientSearchResult => patientSearchAPISuccess({ patientSearchResult, pageIdentifier })),
          catchError(error => of(patientSearchAPIFailure({ errorMessage: error.message, pageIdentifier })))
        );
      })
    )
  });

  loadBulkEmailPatientSearchAPI$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(invokedBulkEmailPatientSearchAPI),
      concatMap(({ patientSearchFilter, pageIdentifier }) => {
        return this.patientSearchAPIService.getBulkEmailPatientSearchData(patientSearchFilter).pipe(
          map(patientSearchResult => patientBulkEmailSearchAPISuccess({ patientSearchResult, pageIdentifier })),
          catchError(error => of(patientBulkEmailSearchAPIFailure({ errorMessage: error.message, pageIdentifier })))
        );
      })
    )
  });


  loadBulkEmailBasicPatientSearchAPI$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(invokedBulkBasicPatientSearchAPI),
      concatMap(({ patientSearchFilter, pageIdentifier }) => {
        return this.patientSearchAPIService.getBulkBasicPatientSearchData(patientSearchFilter).pipe(
          map(bulkEmailBasicPatientSearchResult => patientBulkBasicPatientSearchAPISuccess({ bulkEmailBasicPatientSearchResult, pageIdentifier })),
          catchError(error => of(patientBulkEmailSearchAPIFailure({ errorMessage: error.message, pageIdentifier })))
        );
      })
    )
  });

  loadPatientNameAPI$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(invokedPatientNameAPI),
      concatMap(({ patientSearchFilter, pageIdentifier=patientSearchTypes.patientSearch }) => {
        return this.patientSearchAPIService.getPatientNameData(patientSearchFilter.firstName, patientSearchFilter.lastName).pipe(
          map(patientNameSearchResult => patientNameAPISuccess({ patientNameSearchResult, pageIdentifier })),
          catchError(error => of(patientNameAPIFailure({ errorMessage: error.message, pageIdentifier })))
        );
      })
    )
  });

  loadPracticePatientStatusAPI$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(invokedPracticePatientStatusAPI),
      concatLatestFrom(() => this.store.select(selectPracticePatientStatus)),
      mergeMap(([action, practicePatientStatuses]) => {
        if (practicePatientStatuses.length > 0) return EMPTY;
        return this.patientSearchAPIService.getPracticePatientStatus().pipe(
          map(practicePatientStatus => practicePatientStatusAPISuccess({ practicePatientStatus, pageIdentifier: action.pageIdentifier })),
          catchError(error => of(practicePatientStatusAPIFailure({ errorMessage: error.message, pageIdentifier: action.pageIdentifier })))
        );
      })
    )
  });

  loadPracticeLocationAPI$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(invokedPracticeLocationAPI),
      concatLatestFrom(() => this.store.select(selectPracticeLocation)),
      mergeMap(([action, practiceLocation]) => {
        if (practiceLocation.length > 0) return EMPTY;
        return this.patientSearchAPIService.getPracticeLocation().pipe(
          map(practiceLocation => practiceLocationAPISuccess({ practiceLocation, pageIdentifier: action.pageIdentifier })),
          catchError(error => of(practiceLocationAPIFailure({ errorMessage: error.message, pageIdentifier: action.pageIdentifier })))
        );
      })
    )
  })
}