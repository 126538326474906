import { createAction, props } from "@ngrx/store";
import {
    PatientBasicInfoModel,
    PatientNameSearchResultModel, PatientSearchFilterModel,
    PatientSearchResultModel, PracticeLocation, PracticePatientStatus
} from "../../types/patient-search-type";

export const invokedPatientSearchFilter = createAction('[SearchPatient API] invoke Search Filter', props<{ patientSearchFilter: PatientSearchFilterModel, pageIdentifier?: string }>());
export const invokedBulkPatientSearchFilter = createAction('[Bulk SearchPatient API] invoke Search Filter', props<{ patientSearchFilter: PatientSearchFilterModel, pageIdentifier?: string }>());

export const invokedPatientSearchAPI = createAction('[SearchPatient API] Invoke SearchPatient', props<{ patientSearchFilter: PatientSearchFilterModel, pageIdentifier?: string }>());
export const patientSearchAPISuccess = createAction('[SearchPatient API]  SearchPatient Success', props<{ patientSearchResult: PatientSearchResultModel[], pageIdentifier?: string }>());
export const patientSearchAPIFailure = createAction('[SearchPatient API]  SearchPatient Failure', props<{ errorMessage: string, pageIdentifier?: string }>());

export const invokedBulkEmailPatientSearchAPI = createAction('[BulkSearchPatient API] Invoke SearchPatient', props<{ patientSearchFilter: PatientSearchFilterModel, pageIdentifier?: string }>());
export const patientBulkEmailSearchAPISuccess = createAction('[BulkSearchPatient API]  SearchPatient Success', props<{ patientSearchResult: PatientSearchResultModel[], pageIdentifier?: string }>());
export const patientBulkEmailSearchAPIFailure = createAction('[BulkSearchPatient API]  SearchPatient Failure', props<{ errorMessage: string, pageIdentifier?: string }>());

export const invokedBulkBasicPatientSearchAPI = createAction('[BulkBasicPatientSearch API] Invoke BulkBasicPatientSearch', props<{ patientSearchFilter: PatientSearchFilterModel, pageIdentifier?: string }>());
export const patientBulkBasicPatientSearchAPISuccess = createAction('[BulkBasicPatientSearch API]  BulkBasicPatientSearch Success', props<{ bulkEmailBasicPatientSearchResult: PatientBasicInfoModel[], pageIdentifier?: string }>());
export const bulkBasicPatientSearchAPIFailure = createAction('[BulkBasicPatientSearch API]  BulkBasicPatientSearch Failure', props<{ errorMessage: string, pageIdentifier?: string }>());

export const bulkSelectedPatients = createAction('[bulkSelectedPatients]  bulk email Selected basic details', props<{ patients: PatientBasicInfoModel[] }>());

export const invokedPatientNameAPI = createAction('[GetPatientByName API] Invoke GetPatientByName', props<{ patientSearchFilter: PatientSearchFilterModel, pageIdentifier?: string }>());
export const patientNameAPISuccess = createAction('[GetPatientByName API] GetPatientByName Success', props<{ patientNameSearchResult: PatientNameSearchResultModel[], pageIdentifier?: string }>());
export const patientNameAPIFailure = createAction('[GetPatientByName API] GetPatientByName Failure', props<{ errorMessage: string, pageIdentifier?: string }>());

export const invokedPracticePatientStatusAPI = createAction('[SearchPatient API] Invoke Action', props<{ pageIdentifier?: string }>());
export const practicePatientStatusAPISuccess = createAction('[GetPatientStatuses API] GetPatientStatuses Success', props<{ practicePatientStatus: PracticePatientStatus[], pageIdentifier?: string }>());
export const practicePatientStatusAPIFailure = createAction('[GetPatientStatuses API] GetPatientStatuses Failure', props<{ errorMessage: string, pageIdentifier?: string }>());

export const invokedPracticeLocationAPI = createAction('[SearchPatient API] Invoke Action', props<{ pageIdentifier?: string }>());
export const practiceLocationAPISuccess = createAction('[GetPracticeLocations API] GetPracticeLocations Success', props<{ practiceLocation: PracticeLocation[], pageIdentifier?: string }>());
export const practiceLocationAPIFailure = createAction('[GetPracticeLocations API] GetPracticeLocations Failure', props<{ errorMessage: string, pageIdentifier?: string }>());

export const closeErrorAlert = createAction('[SearchPatient API] Error Alert', props<{ pageIdentifier?: string }>());
export const patientSearchStateDestroy = createAction('[patientSearchStateDestroy] invoke Destroy');
export const bulkPatientSearchStateDestroy = createAction('[Bulk PatientSearchStateDestroy] invoke Destroy');
