import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SelectionRange } from '@progress/kendo-angular-dateinputs';
import { DateRange } from '../../../types/shared-types';

@Component({
  selector: 'app-form-control-daterange',
  template: `
  <div class="position-relative ms-2" >
    <input #anchor class="form-control pe-5" readonly (focus)="toggle(true)" [placeholder]=placeholder
      value="{{ range?.start | date:'MM/dd/yyyy' }}{{ range?.start? '-': null }}{{ range?.end | date:'MM/dd/yyyy' }}" />
      <div class="position-absolute text-box-with-button ">
        <i class="feather icon-x f-16 ms-2 pt-1 pe-2 text-c-red cursor-pointer" 
        (click)="clearDateRange()" *ngIf="range?.start"
        ></i>
        <i class="fa fa-solid fa-calendar-alt text-dark pe-2" (click)="this.toggleAndFocusInput()"></i>
      </div>
    </div>
    <kendo-popup #popup [anchor]="anchor" (anchorViewportLeave)="show = false" *ngIf="show">
      <kendo-multiviewcalendar kendoDateRangeSelection
        (selectionRange)="range"
        (selectionRangeChange)="onChange($event)">
      </kendo-multiviewcalendar>
      <button type="button" class="btn btn-light-primary" aria-label="Close" (click)="applyRange()">Apply</button>
    </kendo-popup>
  `
})
export class FormControlDateRangeComponent implements OnInit, OnDestroy {
  @Input() placeholder!: string;
  @Input() clearDate!: boolean;
  @Input() startDate!: Date | null;
  @Input() endDate!: Date | null;
  @Output() setRange = new EventEmitter<DateRange | null>();

  @ViewChild('anchor') public anchor!: ElementRef;
  @ViewChild('popup', { read: ElementRef }) public popup!: ElementRef;

  show = false;
  range!: SelectionRange | null;

  emitDateRange: DateRange = { startDate: null, endDate: null };

  ngOnInit(): void {
    document.addEventListener('click', this.documentClick.bind(this));
    if (this.startDate && this.endDate) {
      this.range = { start: this.startDate, end: this.endDate };
      this.mapEmitDateRange();
    }
  }

  ngOnDestroy(): void {
    document.removeEventListener('click', this.documentClick.bind(this));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['clearDate'] && changes['clearDate'].currentValue) 
      this.clearDateRange()
  }

  documentClick(event: Event): void {
    if (!this.contains(event?.target)) {
      this.toggle(false);
      this.mapRangeByEmitDateRange();
    }
  }

  clearDateRange(): void {
    this.range = null
    this.emitDateRange = {startDate:null, endDate:null}
    this.setRange.emit(null);
  }

  applyRange(): void {
    this.toggle(false);
    if (this.range && this.range.start && this.range.end) {
      this.mapEmitDateRange();
      this.setRange.emit(this.emitDateRange);
    }
  }

  toggleAndFocusInput(): void {
    this.toggle();
    if(this.show)
     setTimeout(() => { this.anchor.nativeElement.focus(); }, 0);
  }

  toggle(show?: boolean): void {
    this.show = show !== undefined ? show : !this.show;
  }

  onChange(e: SelectionRange): void {
    this.range = e
  }

  mapEmitDateRange() {
    if (this.range && this.range.start && this.range.end) {
      this.emitDateRange.startDate = this.range.start;
      this.emitDateRange.endDate = this.range.end;
    }
  }

  mapRangeByEmitDateRange() {
    if (this.emitDateRange && this.emitDateRange.startDate && this.emitDateRange.endDate) 
      this.range = { start:this.emitDateRange.startDate,end:this.emitDateRange.endDate}
    else 
      this.range= null
  }

  private contains(target: EventTarget | null): boolean {
    return (
      this.anchor.nativeElement.contains(target) ||
      (this.popup ? this.popup.nativeElement.contains(target) : false)
    );
  }
}