<div>
    <div class="search-suggestion">
        <div class="search-container">
            <form [formGroup]="patientSearchFilter">
                <div class="search search-focus">
                    <div class="input-dropdown position-relative">
                        <label class="searchbutton border-0 rounded-3 search-active" title="Search patients by last name, first name">
                            <span class="mglass m-t-5 m-l-10" [ngClass]="[onSearchInputFocuse ? 'text-primary' : '']">&#9906;</span>
                        </label>
                        <input type="text" #patientSearchInput class="form-control patient-search-input-filter hei-45" autofocus
                            (clickOutside)="onClickedOutside()" formControlName="patientName"
                            (input)="onPatientNameChange(patientSearchFilter);onSearchInputFocused();patientNameloading=true" value="patientName"
                            placeholder="last name, first name " bindLabel="patientName"
                            (focus)="onSearchInputFocused()" (keydown)="onKeydown($event, searchfilter)"
                            input-dropdown-validator>
                        <div class="patient-search-select mt-2" *ngIf="!searchResultFromChat && !isFromChat">
                            <div *ngIf="dropdownVisible && !spinnerloading">
                                <ul class="patient-search-select-box m-0 py-2 px-0" #patientList>
                                    <li class="py-2 px-3 d-flex align-items-center cursor-pointer justify-content-between" (click)="selectPatientName(item);onSearchInputBlurred();"
                                        *ngFor="let item of this.patientNameSearchResult; let i = index"
                                        (mouseenter)="setActive(i);"
                                        (keydown)="onKeydown($event, searchfilter)" [ngClass]="{'active': activeItemIndex === i}">
                                        <div class="d-flex align-items-center gap-2 col-8">
                                            <span class="first-name-letter btn-primary p-3">{{getInitials(item.patientFirstName)}}</span>
                                            <span> {{item.patientLastName }}, {{ item.patientFirstName }}</span>
                                        </div>
                                        <div class="d-flex align-items-center cl-icons gap-1">
                                            <!--<i class="feather icon-phone f-20 text-primary cursor-pointer" (click)="onCallClick(item);$event.stopPropagation()" *applicationFeature="'isVoIpenabled'"></i>-->
                                            <img src="assets/images/svg/new-tab.svg" (click)="navigateToPatientSummary(item);$event.stopPropagation()" class="wid-20 hei-20">
                                            <i class="feather icon-message-square text-secondary f-20 cursor-pointer" (click)="onChatClick(item);$event.stopPropagation()" *applicationFeature="'isChatEnabled'"></i>
                                            <i class="feather icon-image text-info f-20 cursor-pointer" (click)="navigateToImage(item);$event.stopPropagation()"></i>
                                            <img src="assets/images/svg/tx-card-search.svg" width="20" height="20" class="img-fluid" (click)="navigateToTXCard(item);$event.stopPropagation()">
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div *ngIf="onSearchInputFocuse && !dropdownVisible && (patientSearchFilter.value.patientName) && (!patientNameloading)
                                   && this.patientNameSearchResult.length===0 && !spinnerloading">
                                <div class="w-100 text-center p-3">
                                    <img src="../../../../../assets/images/svg/no-search-icon.svg" class="w-25"/>
                                    <h5 class="ms-2 f-18 mt-2"> No Record Found</h5>
                                    <p class="f-12">Please try again with another patient name</p>
                                </div>
                            </div>
                            <div *ngIf="pinNoRecordFound && !spinnerloading">
                                <div class="w-100 text-center p-3">
                                    <img src="../../../../../assets/images/svg/no-search-icon.svg" class="w-25"/>
                                    <h5 class="ms-2 f-18 mt-2"> No Record Found</h5>
                                </div>
                            </div>
                            <ng-container *ngIf="spinnerloading">
                                <div class="mt-3">
                                    <app-lookup-edit-list-skeleton class="remove-card-shadow remove-padding-skeleton" [fields]="3"></app-lookup-edit-list-skeleton>
                                </div>
                            </ng-container>
                        </div>
                        <!-- [perfectScrollbar]="{suppressScrollX: true}"   -->
                        <div class="custom-scroll-y overflow-x-hidden chat-patient-search-height mt-2"  *ngIf="searchResultFromChat && isFromChat">
                            <ng-container *ngFor="let patient of this.patientNameSearchResult; let i = index">
                                <div class="d-flex justify-content-between py-2 border-bottom"  *ngIf="!spinnerloading">
                                    <div class="cl-main-us d-flex align-items-center gap-2">
                                        <div class="us-icon-cl">
                                            <div class="d-flex wid-35 hei-35 align-items-center justify-content-center bg-primary text-white img-radius f-12" ngClass="circledata{{i%4}}">
                                                {{patient.patientLastName.charAt(0)}}{{patient.patientFirstName.charAt(0)}}</div>
                                        </div>
                                        <div class="cl-text">
                                            <h6 class="mb-1">{{patient.patientLastName}}, {{patient.patientFirstName}} </h6>
                                            <p class="mb-0">{{patient.patientPrimaryPhone}}</p>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center cl-icons me-2 gap-1">
                                        <!--<i class="feather icon-phone f-20 text-primary cursor-pointer" (click)="onCallClick(patient)" *applicationFeature="'isVoIpenabled'"></i>-->
                                        <i class="feather icon-message-square text-secondary f-20 cursor-pointer" (click)="onChatClick(patient)" *applicationFeature="'isChatEnabled'"></i>
                                        <!-- <div (click)="onCallClick(patient)">
                                            <img src="assets/images/icons/missed-call.png" width="20" height="20" class="img-fluid">
                                        </div>
                                        <div (click)="onChatClick(patient)">
                                            <img src="assets/images/icons/chat.png" width="20" height="20" class="img-fluid">
                                        </div> -->
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="spinnerloading">
                                <app-lookup-edit-list-skeleton class="remove-card-shadow remove-padding-skeleton" [fields]="4"></app-lookup-edit-list-skeleton>
                            </ng-container>
                        </div>
                        <div class="position-absolute d-flex align-items-center patient-search-filters-icon hei-45 m-r-10" *ngIf="!isFromChat">
                            <i (click)="clearFilter();" title="Clear search filters" class="feather icon-x f-22 text-secondary"></i>
                            <img (click)="openPin();" title="Recently visited patients"   [ngClass]="[!onSearchInputFocuse && dropdownVisible ? 'open-dropdown-Highlight' : '', pinNoRecordFound ? 'open-dropdown-Highlight' : '']" src="assets/images/svg/patient-pin.svg" class="wid-20">
                            <div class="filter-icon" title="Search patients" >
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 11.223 11.223"
                                    (click)="openTop(searchfilter);"
                                    [ngClass]="[searchPatientsByFilter ? 'open-dropdown-Highlight' : '']">
                                    <path id="ic_tune_24px"
                                        d="M3,11.729v1.247H6.741V11.729ZM3,4.247V5.494H9.235V4.247Zm6.235,9.976V12.976h4.988V11.729H9.235V10.482H7.988v3.741ZM5.494,6.741V7.988H3V9.235H5.494v1.247H6.741V6.741Zm8.729,2.494V7.988H7.988V9.235ZM10.482,6.741h1.247V5.494h2.494V4.247H11.729V3H10.482Z"
                                        transform="translate(-3 -3)" fill="#7F7F7F" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<ng-template #searchfilter let-offcanvas>
    <div class="position-relative">
        <button type="button"
            class="btn btn-light-primary wid-30 hei-30 d-flex align-items-center justify-content-center border-radius-50 position-absolute me-3 p-2 cancel-btn"
            aria-label="Close" (click)="closeOffcanvas(offcanvas)">
            <i class="feather icon-x f-16"></i>
        </button>
    </div>
    <app-patient-search-filter (closeSearchResultOverlay)="closeOffcanvas(offcanvas)"></app-patient-search-filter>
</ng-template>