import { MenuItem } from '../store/menu/menu.state';
import { moduleName,featureName,operationName } from 'src/app/features/user/constant/user-role-constant';

export const practiceMenuItemConstant: MenuItem[] = [
    {
        id: 1,
        title: 'Info',
        type: 'item',
        url: 'practice/practice-personal-lookup/personal/:id',
        classes: 'nav-item',
        icon: 'assets/images/menuicon/sub-menu/user.svg',
        hiddenFunction:  (userRole, userAuthService) => 
            !userAuthService?.hasPermission([{ moduleName:moduleName.practice,featureName:featureName.practicePersonalInfo,operationName:operationName.all }])
    },
    {
        id: 2,
        title: 'Lookup',
        type: 'item',
        url: 'practice/practice-lookup/:id',
        classes: 'nav-item',
        icon: 'assets/images/menuicon/sub-menu/dentist.svg',
        hiddenFunction:  (userRole, userAuthService) => 
            !userAuthService?.hasPermission([{ moduleName:moduleName.practice,featureName:featureName.practicePersonalInfo,operationName:operationName.all }])
    },
    {
        id: 3,
        title: 'Users',
        type: 'item',
        url: 'practice/practice-users-lookup/:id',
        classes: 'nav-item',
        icon: 'assets/images/menuicon/sub-menu/roles.svg',
        hiddenFunction:  (userRole, userAuthService) => 
            !userAuthService?.hasPermission([{ moduleName:moduleName.practice,featureName:featureName.practicePersonalInfo,operationName:operationName.all }])
    },
    {
        id: 4,
        title: 'Schedule',
        type: 'item',
        url: 'practice/Schedule/:id',
        classes: 'nav-item',
        icon: 'assets/images/menuicon/sub-menu/schedule.svg',
        hiddenFunction:  (userRole, userAuthService) => 
            !userAuthService?.hasPermission([{ moduleName:moduleName.practice,featureName:featureName.practicePersonalInfo,operationName:operationName.all }])
    },
    {
        id: 5,
        title: 'Tx Card',
        type: 'item',
        url: 'practice/txcard-configuration/:id',
        classes: 'nav-item',
        icon: 'assets/images/menuicon/sub-menu/tx-card.svg',
        hiddenFunction:  (userRole, userAuthService) => 
            !userAuthService?.hasPermission([{ moduleName:moduleName.practice,featureName:featureName.practicePersonalInfo,operationName:operationName.all }])
    },
    {
        id: 6,
        title: 'Template',
        type: 'item',
        url: 'practice/template-config/:id',
        classes: 'nav-item',
        icon: 'assets/images/menuicon/sub-menu/letter.svg',
        hiddenFunction:  (userRole, userAuthService) => 
            !userAuthService?.hasPermission([{ moduleName:moduleName.practice,featureName:featureName.practicePersonalInfo,operationName:operationName.all }])
    },
    {
        id: 7,
        title: 'Treatment',
        type: 'item',
        url: 'practice/practice-treatment/:id',
        classes: 'nav-item',
        icon: 'assets/images/menuicon/sub-menu/treatment.svg',
        hiddenFunction:  (userRole, userAuthService) => 
            !userAuthService?.hasPermission([{ moduleName:moduleName.practice,featureName:featureName.practicePersonalInfo,operationName:operationName.all }])
    },
    {
        id: 8,
        title: 'Medical',
        type: 'item',
        url: 'practice/practice-medical/:id',
        classes: 'nav-item',
        icon: 'assets/images/menuicon/sub-menu/medical.svg',
        hiddenFunction:  (userRole, userAuthService) => 
            !userAuthService?.hasPermission([{ moduleName:moduleName.practice,featureName:featureName.practicePersonalInfo,operationName:operationName.all }])
    },
    {
        id: 9,
        title: 'Findings',
        type: 'item',
        url: 'practice/practice-forms/:id',
        classes: 'nav-item',
        icon: 'assets/images/menuicon/sub-menu/forms.svg',
        hiddenFunction:  (userRole, userAuthService) => 
            !userAuthService?.hasPermission([{ moduleName:moduleName.practice,featureName:featureName.practicePersonalInfo,operationName:operationName.all }])
    }
]