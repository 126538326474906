export const letterTemplateConstants = {
  letterTemplateCreateSuccess: 'Template created successfully.',
  letterTemplateUpdateSuccess: 'Template updated successfully.',
  letterTemplateDeactivateWarning: 'We would like to confirm if you want to deactivate this template. Are you sure?',
  letterTemplateActivateSuccess: 'Template activated successfully.',
  letterTemplateDeactivateSuccess: 'Template deactivated successfully.',
  letterTemplateActivateWarning: 'We would like to confirm if you want to activate this template. Are you sure?',
  remainderTemplateUpdateWarning: 'We would like to confirm if you want to update this template. Are you sure?',
  letterTemplateExistsWarning: 'Sorry, This template name is already exists.',
  letterTemplateExistsAndInActiveWarning: 'Sorry, This template name is already exists and deactivated.',
  remainderTemplateExistWarning: 'Sorry, a template with the same category and schedule interval already exists.',
  remainderTemplateExistsAndInActiveWarning: 'Sorry, a template with the same category and schedule interval already exists and deactivated.',
}

export enum templateCategory {
  LetterTemplate = "Letter Template",
  CustomTemplate = "Custom Template",
  ReminderTemplate = "Reminder Template",
  Miscellaneous = "Miscellaneous",
  PatientFollowup = "Patient Followup",
  InCompletePatientForm = "Incomplete Patient Form",
  UserWelcomeEmail = "User Welcome Email",
  BirthdayReminder = "Birthday Reminder",
  SignAtHomeTemplate = "Sign at Home Template"
}

export const remainderType = {
  Before: "Before",
  After: "After",
  None: " None "
}

export const skipTemplateCategory: string[] = [
  templateCategory.Miscellaneous,
  templateCategory.PatientFollowup
]

export const transactionCategories: string[] = [
  ...skipTemplateCategory.filter(category => category !== templateCategory.InCompletePatientForm),
  templateCategory.LetterTemplate,
  templateCategory.CustomTemplate
]

export const allTemplateCategory = {
  ...templateCategory,
  appointmentReminder: 'Appointment Reminder',
  appointmentConfirmation: 'Appointment Confirmation',
  unConfirmedAppointment: 'UnConfirmed Appointment',
  paymentReminder: 'Payment Reminder',
  pastDueReminder: 'Past Due Reminder',
  processedPaymentNotification: 'Processed Payment Notification',
  failedPaymentNotification: 'Failed Payment Notification',
}

export const templateCategories = [
  {
    templateCategoryId: 1,
    templateCategoryName: templateCategory.LetterTemplate,
  },
  {
    templateCategoryId: 10,
    templateCategoryName: templateCategory.CustomTemplate,
  },
  {
    templateCategoryId: 11,
    templateCategoryName: templateCategory.InCompletePatientForm,
  },
  {
    templateCategoryId: 12,
    templateCategoryName: templateCategory.UserWelcomeEmail,
  }
];